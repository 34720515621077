import { Component, OnInit } from '@angular/core';
import { UserService } from './core/services/user.service';
declare var moment: any;


// TODO: Configurar no site
// TODO: Colocar backup executar as 19

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'lena-atelie';

  constructor(){

  }

  ngOnInit(){
    moment.tz.setDefault('America/Sao_Paulo');
    var body = document.body;
    body.classList.add("hold-transition");
    body.classList.add("skin-purple");
    // body.classList.add("sidebar-mini");
    body.classList.add("layout-top-nav");
  }


}
