import { IResource } from "../interfaces/IResouce";
import { Resource } from "./resource.model";

export class Attachment extends Resource implements IResource {
  id_customer: number;
  file_name: string;
  file_url: string;
  file_gd_id: string;

  constructor(objectJson?: any) {
    super(objectJson);
  }
}
