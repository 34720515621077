import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { User } from '../models/user.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Serializer } from '../models/serializer.model';
import { of, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService extends ResourceService<User> {

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.info_register_api,
      'v1',
      'users',
      new Serializer<User>(new User)
    )
  }

  public listStatus(): Observable<any>{
    return of({
      data: [
        { id: 1, display_name: 'Ativo' },
        { id: 2, display_name: 'Inativo' },
        { id: 3, display_name: 'Bloqueado' }
      ]
    }); 
  }


  
}
