import { IResource } from '../interfaces/IResouce';
import { Resource } from './resource.model';

export class Customer extends Resource implements IResource {
    name: string;
    code: string;
    email: string;
    phone1: string;
    phone2: string;

    constructor(objectJson?: any) {
        super(objectJson);
    }

}
