import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Appointment } from "src/app/core/models/appointment.model";
import { Reason } from 'src/app/core/models/reason.model';
import { User } from 'src/app/core/models/user.model';
import { AppointmentService } from "src/app/core/services/appointment.service";
import { ReasonService } from 'src/app/core/services/reason.service';
import { UserService } from 'src/app/core/services/user.service';
import { BaseManageComponent } from "src/app/shared/base-manage/base-manage.component";
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2'

declare var $: any;
declare var moment: any;
@Component({
  selector: "scheduele-manage-route",
  templateUrl: "./manage.component.html",
  styleUrls: ["./manage.component.sass"]
})
export class SchedueleManageComponent extends BaseManageComponent<Appointment>
  implements OnInit, AfterContentInit {
  public entityForm: FormGroup;
  public entityId: number;
  public entityObject: Appointment;
  public isView: boolean;
  public isUnavailable: boolean;
  public reasonsList: Reason[];
  public usersList: User[];
  @Input() insideDialog: boolean = false;
  @Input() disabled: boolean;
  @Input() data: any;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public entitySvc: AppointmentService,
    public reasonSvc: ReasonService,
    public userSvc: UserService,
    public formBuilder: FormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    if (this.insideDialog) {
      this.initInDialog();
    } else {
      this.initRoute();
    }
  }

  initRoute() {
    this.entityId = +this.activatedRoute.snapshot.params.id;
    this.isView = this.activatedRoute.snapshot.routeConfig.path == "view/:id";

    this.initForm();
    if (!!this.entityId) {
      this.getData(this.entityId).then(response => {
        this.fillForm(this.entityObject);
      });
    }else{
    const dateNow = moment();
    this.entityForm.controls.start_date.setValue(dateNow.format(environment.date_format_moment));
    this.entityForm.controls.end_date.setValue(dateNow.format(environment.date_format_moment));
    this.entityForm.controls.start_time.setValue(dateNow.format('HH:mm'));
    this.entityForm.controls.end_time.setValue(dateNow.add(1, 'h').format('HH:mm'));
    }
    this.getServices();
    this.getUsersProvider();
  }

  initInDialog() {
    this.initForm();
    if (!!this.data) {
      this.entityId = +this.data.id;
    }
    this.isView = this.disabled || false;

    if (!!this.entityId) {
      this.getData(this.entityId).then(response => {
          this.fillForm(this.entityObject);
      });
    }
    this.getServices();
    this.getUsersProvider();
  }

  ngAfterContentInit() {
    this.initForm();
    let $this = this;
    setTimeout(() => {
      $(".datepicker")
        .datepicker({
          autoclose: true,
          daysOfWeekDisabled: [0],
          language: "pt-BR"
        })
        .on("changeDate", function(e) {
          
          let controlName = e.target.attributes.formcontrolname.value;
          $this.entityForm.controls[controlName].setValue(e.date);
        });
    }, 300);
  }

  setRange(data) {
   
    this.entityForm.controls.start = data.start;
    this.entityForm.controls.end = data.end;
    this.entityForm.updateValueAndValidity();
  }

  fillForm(entityObject) {
   
    // this.f.start_date.setValue(moment(entityObject.start).format('DD/MM/YYYY'));
    // this.f.end_date.setValue(moment(entityObject.end).format('DD/MM/YYYY'));

    this.f.start_time.setValue(moment(entityObject.start).format('HH:mm:ss'));
    this.f.end_time.setValue(moment(entityObject.end).format('HH:mm:ss'));
    this.f.warning_schedule.setValue(entityObject.warning_schedule);

    setTimeout(() => {
      $('.datepicker[formcontrolname="start_date"]').datepicker('setDate', new Date(entityObject.start));
      $('.datepicker[formcontrolname="end_date"]').datepicker('setDate', new Date(entityObject.end));
    }, 100);


    this.f.notes.setValue(entityObject.notes);
    this.f.is_unavailable.setValue(entityObject.is_unavailable);
    this.f.id_customer.setValue(entityObject.id_customer);
    this.f.id_users_provider.setValue(entityObject.id_users_provider);
    this.f.id_reasons.setValue(entityObject.id_reasons);

    if(entityObject.customer){
      let customerGroup = this.f.customer as FormGroup;
      customerGroup.controls.name.setValue(entityObject.customer.name);
      customerGroup.controls.code.setValue(entityObject.customer.code);
      customerGroup.controls.email.setValue(entityObject.customer.email);
      customerGroup.controls.phone.setValue(entityObject.customer.phone);
    }

    if (this.isView) {
      this.entityForm.disable();
    }



    this.f.is_unavailable.updateValueAndValidity();
    this.f.id_customer.updateValueAndValidity();
    this.f.id_users_provider.updateValueAndValidity();
    this.f.id_reasons.updateValueAndValidity();

    let customerGroup = this.f.customer as FormGroup;
    customerGroup.controls.name.updateValueAndValidity();
    customerGroup.controls.code.updateValueAndValidity();
    customerGroup.controls.email.updateValueAndValidity();
    customerGroup.controls.phone.updateValueAndValidity();

   
  }

  getServices() {
    this.reasonSvc.list().subscribe(response => {
      this.reasonsList = response.data;
    });
  }

  getUsersProvider() {
    const filter = {
      status: 1
    }
    this.userSvc.list(filter).subscribe(response => {
      this.usersList = response.data;
    });
  }

  initForm() {
    this.entityForm = this.formBuilder.group({
      start_date: [null, [Validators.required]],
      start_time: [null, [Validators.required]],
      end_date: [null, [Validators.required]],
      end_time: [null, [Validators.required]],
      notes: [null],
      is_unavailable: [null],
      warning_schedule: [null],
      id_customer: [null],
      id_users_provider: [null, [Validators.required]],
      id_reasons: [null, [Validators.required]],
      customer: this.formBuilder.group({
        code: [null, [Validators.required]],
        name: [null, [Validators.required]],
        email: [null, [Validators.required]],
        phone: [null, [Validators.required]]
      })
    });

    this.entityForm.controls.is_unavailable.valueChanges.subscribe(values => {
      this.isUnavailable = values;
      if(values){
        this.f.id_customer.clearValidators();
        this.f.id_users_provider.clearValidators();
        this.f.id_reasons.clearValidators();

        this.f.id_customer.updateValueAndValidity();
        this.f.id_users_provider.updateValueAndValidity();
        this.f.id_reasons.updateValueAndValidity();

        let customerGroup = this.f.customer as FormGroup;
        customerGroup.controls.name.clearValidators();
        customerGroup.controls.code.clearValidators();
        customerGroup.controls.email.clearValidators();
        customerGroup.controls.phone.clearValidators();

        customerGroup.controls.name.updateValueAndValidity();
        customerGroup.controls.code.updateValueAndValidity();
        customerGroup.controls.email.updateValueAndValidity();
        customerGroup.controls.phone.updateValueAndValidity();
      }else{
        this.f.id_customer.setValidators([Validators.required]);
        this.f.id_users_provider.setValidators([Validators.required]);
        this.f.id_reasons.setValidators([Validators.required]);

        this.f.id_customer.updateValueAndValidity();
        this.f.id_users_provider.updateValueAndValidity();
        this.f.id_reasons.updateValueAndValidity();

        let customerGroup = this.f.customer as FormGroup;
        customerGroup.controls.name.setValidators([Validators.required]);
        customerGroup.controls.code.setValidators([Validators.required]);
        customerGroup.controls.email.setValidators([Validators.required]);
        customerGroup.controls.phone.setValidators([Validators.required]);

        customerGroup.controls.name.updateValueAndValidity();
        customerGroup.controls.code.updateValueAndValidity();
        customerGroup.controls.email.updateValueAndValidity();
        customerGroup.controls.phone.updateValueAndValidity();
      }
    });
    this.entityForm.markAsPristine()
    this.entityForm.markAsDirty()
    this.entityForm.markAsUntouched()
  }

  successCreateCallback = function(response) {
    let $this = this;
    Swal.fire("Sucesso", "O agendamento foi registrado!", "success").then(
      () => {
        if ($this.insideDialog) {
          $this.entityForm.reset()
          $this.closeDialog.emit({
            event: "successCreate",
            response,
            data: $this.entityForm.value
          });
        } else {
          $this.router.navigate(["/admin/schedule/calendar"]);
        }
      }
    );
  }.bind(this);

  successUpdateCallback = function(response) {
    let $this = this;
    Swal.fire("Sucesso", "O agendamento foi atualizado!", "success").then(
      () => {
        if ($this.insideDialog) {
          $this.entityForm.reset()
          $this.closeDialog.emit({
            event: "successCreate",
            response,
            data: $this.entityForm.value
          });
        } else {
          $this.router.navigate(["/admin/schedule/calendar"]);
        }
      }
    );
  }.bind(this);

  errorCreateCallback = function(error) {
   let $this = this;
    Swal.fire("Erro!", "Não foi possivel criar o agendamento!\nTente novamente, e em caso de persistência entre em contato com o suporte", "error").then(
      () => {
        $this.inLoading = false;
      }
    );
  }.bind(this);

  errorUpdateCallback = function(error) {
    let $this = this;
    Swal.fire("Erro!", "Não foi atualizar o agendamento!\nTente novamente, e em caso de persistência entre em contato com o suporte", "error").then(
      () => {
        $this.inLoading = false;
      }
    );
  }.bind(this);

  onSubmit() {
    let dataSend = this.entityForm.value;
    dataSend.start = moment(dataSend.start_date, environment.date_format_moment).format("YYYY-MM-DD");
    dataSend.end = moment(dataSend.end_date, environment.date_format_moment).format("YYYY-MM-DD");
    dataSend.start += ` ${dataSend.start_time}:00`;
    dataSend.end += ` ${dataSend.end_time}:00`;

    dataSend.start =  new Date(dataSend.start).toISOString();
    dataSend.end =  new Date(dataSend.end).toISOString();
    this.inLoading = true;
    if (!!this.entityId) {
      this.entitySvc
        .update(this.entityId, dataSend)
        .subscribe(this.successUpdateCallback, this.errorUpdateCallback, () => {
          this.inLoading = false;
        });
    } else {
      this.entitySvc
        .create(dataSend)
        .subscribe(this.successCreateCallback, this.errorCreateCallback, () => {
          this.inLoading = false;
        });
    }
  }

  closeDialogCall(e) {
    this.closeDialog.emit({
      event: e
    });
  }
}
