import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';

import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Appointment } from 'src/app/core/models/appointment.model';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { environment } from './../../../environments/environment';
import { SolicitationService } from 'src/app/core/services/solicitation.service';
import { BackupService } from 'src/app/core/services/backup.service';
import Swal from "sweetalert2";

// TODO: Acrescentar logs de acesso
@Component({
  selector: 'app-header-top-navigation',
  templateUrl: './header-top-navigation.component.html',
  styleUrls: ['./header-top-navigation.component.scss']
})
export class HeaderTopNavigationComponent implements OnInit, OnDestroy {
  timeUpdateWarninglist: any
  itensListMenu = [
    {
        label: 'Agenda',
        icon: 'calendar',
        items: [],
        routerLink: '/admin/schedule/calendar',
        secured: ['admin','consultant','secretary','seamstress']

      },
      {
        label: 'Agendamentos removidos',
        icon: 'trash',
        items: [],
        routerLink: '/admin/schedule/list/removed',
        secured: ['admin']

    },
    {
        label: 'Solicitações',
        icon: 'comment-o',
        items: [],
        routerLink: '/admin/solicitations',
        secured: ['admin','consultant','secretary','seamstress']
    },
    {
        label: 'Relatórios',
        icon: 'file-o',
        items: [],
        routerLink: '/admin/report',
        secured: ['admin','consultant','secretary','seamstress']
      },
      {
        label: 'Usuários',
        icon: 'users',
        items: [],
        routerLink: '/admin/users',
        secured: ['admin']
    },
    {
        label: 'Costureiras',
        icon: 'thumb-tack',
        items: [],
        routerLink: '/admin/seamstresses',
        secured: ['admin']
    },
    {
        label: 'Templates SMS',
        icon: 'files-o',
        items: [],
        routerLink: '/admin/templates-sms',
        secured: ['admin']
    },
    {
        label: 'Tipos de eventos',
        icon: 'tags',
        items: [],
        routerLink: '/admin/event-reasons',
        secured: ['admin']
    },
    {
        label: 'Motivo',
        icon: 'check-square-o',
        items: [],
        routerLink: '/admin/reasons',
        secured: ['admin']
    },
    {
        label: 'Regras',
        icon: 'gears',
        items: [],
        routerLink: '/admin/schedule-rules',
        secured: ['admin']
    },
    {
        label: 'Logs',
        icon: 'info-circle',
        items: [],
        routerLink: '/admin/logs',
        secured: ['admin','consultant','secretary','seamstress']
    },

  ];

  qtd_solicitations = 0;

//   {
//     label: 'Gerenciamento',
//     items: [
//       {
//         label: 'Empresas licenciadoras',
//         // icon:'fa-user',
//         routerLink: '/admin/licensing-company',
//       },
//       {
//         label: 'Empresas',
//         // icon:'fa-user',
//         routerLink: '/admin/companies',
//       },
//       {
//         label: 'Licenças',
//         // icon:'fa-user',
//         routerLink: '/admin/licenses',
//       },
//       {
//         label: 'Empreendedores',
//         // icon:'fa-user',
//         routerLink: '/admin/entrepreneurs',
//       },
//       {
//         label: 'Empreendimentos',
//         // icon:'fa-building',
//         routerLink: '/admin/enterprises',
//       },
//       {
//         label: 'Usuários',
//         // icon:'fa-users',
//         routerLink: '/admin/users',
//       }
//     ]
// },

  environment = environment
  user: User;
  warningSchedulesList: Appointment[] = []
  constructor(
    public authSvc: AuthService,
    private solicitationSvc: SolicitationService,
    public appointmentSvc: AppointmentService,
    public backupSvc: BackupService,
    public changeDetector: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.user = this.authSvc.getUser()
    this.setWarningList()
    this.changeDetector.detectChanges()

    this.getSolicitationNumbers()
  }
  ngOnDestroy(){
    // clearTimeout(this.timeUpdateWarninglist)
  }

  doBackup(){
    let $this = this;

    Swal.fire({
      title: "Atenção!",
      text:
        "Deseja realmente gerar o backup?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, desejo!",
      cancelButtonText: "Não",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return $this.backupSvc.generateBackup().toPromise()
        .then(response => {
          console.log('preConfirm')
          console.log(response)
            if (response.error) {
              throw new Error(response.message)
            }
            return response
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result: any) => {
      console.log('then')
      console.log(result)
      if (result.value) {
        Swal.fire(`Sucesso!`,`${result.value.message}`,'success')
      }
    })

      // Swal.fire({
      //   title: "Atenção!",
      //   text:
      //     "Deseja realmente gerar o backup?",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "Sim, desejo!",
      //   cancelButtonText: "Não"
      // }).then(result => {
      //   if (result.value) {

      //   }
      // });

  }

  logout(){
    this.authSvc.logout()
  }

  async setWarningList(){
    let  returnWarningSchedulesList = await this.getWarningAppointments();
    this.warningSchedulesList = returnWarningSchedulesList.data;
    // this.changeDetector.detectChanges()
    // this.timeUpdateWarninglist = setTimeout((() => {
      // requestAnimationFrame((this.setWarningList).bind(this))
    // }).bind(this), 20000);
  }

  getSolicitationNumbers(){
    this.appointmentSvc.getSolicitationsDisplay().subscribe(
      (response) => {
        console.log(response)
        this.qtd_solicitations = response.solicitations_count;
      }
    )
  }

  async getWarningAppointments(){
      return await this.appointmentSvc.getWarningSchedules().toPromise();
  }

}
