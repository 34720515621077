import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from 'src/app/core/services/resource.service';
import { Serializer } from './../models/serializer.model';
import { Audit } from './../models/audit.model';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
declare var moment: any;
@Injectable({
  providedIn: 'root'
})
export class AuditService extends ResourceService<Audit> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.info_register_api,
      "v1",
      "audits",
      new Serializer<Audit>(new Audit)
    );
  }

  getByCode(code: string): Observable<any> {
    return this.httpClient.post(
      `${this.url}/${this.version}/${this.endpoint}/getByCode`,
      {
        code
      }
    );
  }


  formatAuditing(auditList, labelDic?: any, booleanList = [], dateList = [], blackList = []) {

    auditList.forEach(elementAudit => {
      const auditingFormat = [];
      let old_value;
      let new_value;

      // tslint:disable-next-line: forin
      for (const key in elementAudit.new_values) {
        if (blackList.includes(key)) {
          continue;
        }

        new_value = '-';
        old_value = '-';
        if (elementAudit.new_values.hasOwnProperty(key)) {

          new_value = elementAudit.new_values[key];
        }
        if (elementAudit.old_values.hasOwnProperty(key)) {
          old_value = elementAudit.old_values[key];
        }

        if (booleanList.includes(key)) {
          new_value = (!!elementAudit.new_values[key]) ? 'Sim' : 'Não';
          old_value = (!!elementAudit.old_values[key]) ? 'Sim' : 'Não';
        }
        if (dateList.includes(key)) {
          new_value = (!!elementAudit.new_values[key]) ?
            moment(elementAudit.new_values[key]).format(environment.date_time_format_moment) : '-';
          old_value = (!!elementAudit.old_values[key]) ?
            moment(elementAudit.old_values[key]).format(environment.date_time_format_moment) : '-';
        }

        auditingFormat.push({
          old_value,
          new_value,
          key,
          label: labelDic[key]
        });
      }

      elementAudit.auditing_format = auditingFormat;


    });
  }

}



