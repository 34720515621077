import { Component, OnInit } from '@angular/core';
import { IResource } from 'src/app/core/interfaces/IResouce';
import { ResourceService } from 'src/app/core/services/resource.service';
import Swal from 'sweetalert2'
export abstract class BaseListComponent<T extends IResource> implements OnInit {

  public entitySvc: ResourceService<T>;
  public entityList: T[];
  public confirmationDeleteMessage: string;
  public successDeleteMessage: string;
  public errorDeleteMessage: string;
  public filterParam: string;
  public response: any;
  public getParamObject(param){
    return {}
  }
  constructor() { }

  ngOnInit() {

  }

  getData(filterObject?){
    this.entitySvc.list(filterObject).subscribe(
      (response) => {
        this.response = response;
        this.entityList = response.data;
      }
    )
  }

  remove(id: number){
    let $this = this;
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })


    Swal.fire({
      title: 'Atenção!',
      text: $this.confirmationDeleteMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, desejo!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        $this.entitySvc.delete(id).subscribe(() => {
          Toast.fire({
            icon: 'success',
            title: $this.successDeleteMessage
          })
          $this.getData($this.getParamObject($this.filterParam))
        },
        (error) => {
          Swal.fire("Erro!", $this.errorDeleteMessage, "error")
        });


      }
    })

  }



}
