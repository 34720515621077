import { TemplateSms } from './../../../core/models/template-sms.model';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { Appointment } from "src/app/core/models/appointment.model";
import { SchedueleManageComponent } from "src/app/admin/schedule/manage/manage.component";
import { AuthService } from "src/app/auth/services/auth.service";
import { User } from "src/app/core/models/user.model";
import { AppointmentService } from "src/app/core/services/appointment.service";
import Swal from "sweetalert2";
import { UserService } from "src/app/core/services/user.service";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { toFormData } from "../../file-upload/file-upload.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AttachmentService } from "src/app/core/services/attachment.service";
import { TemplatesSmsService } from "src/app/core/services/templates-sms.service";
declare var moment: any;
@Component({
  selector: "app-schedule-register",
  templateUrl: "./schedule-register.component.html",
  styleUrls: ["./schedule-register.component.scss"]
})
export class ScheduleRegisterComponent implements OnInit, AfterViewInit {
  @ViewChild("formScheduler") manageSchedule: SchedueleManageComponent;
  environment = environment;
  appointment: Appointment;
  templatesSms: TemplateSms[];
  templateSelected: TemplateSms;
  authUser: User;
  userDic: any;
  savingFile: any;
  eventDic = {
    created: "Criado",
    updated: "Atualizado"
  };
  Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    }
  });

  templatesSmsVariables = []

  constructor(
    public dialogRef: MatDialogRef<ScheduleRegisterComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authSvc: AuthService,
    private appointmentSvc: AppointmentService,
    private userSvc: UserService,
    private httpSvc: HttpClient,
    private formBuilder: FormBuilder,
    private attachmentSvc: AttachmentService,
    private _templateSmsSvc: TemplatesSmsService
  ) {
    this.appointment = data.appointment;

    if (this.appointment && this.appointment.id) {
      this.getAppointmentDetail();
      // this.userDic = this.getDicUser()
    }

    this.authUser = this.authSvc.getUser();
    this.getTemplatesSms();
    this.getVariablesOfTemplatesSms();
    this.initForm();
    this.initFormSms();
  }

  getAppointmentDetail() {
    this.appointmentSvc.read(this.appointment.id).subscribe(response => {
      this.appointment = response;
      this.formatAuditing();
    });
  }

  changeTemplate(){
    console.log(this.templateSelected)
    this.formSMS.controls.message.setValue(this.templateSelected.message)
  }

  async getTemplatesSms() {
    this.templatesSms = (await this._templateSmsSvc.list({ qtd: -1 }).toPromise()).data;
  }

  async getVariablesOfTemplatesSms() {
    this.templatesSmsVariables = (await this._templateSmsSvc.getVariables().toPromise());
  }

  async sendSMS(){
    const dataMessage = `
    Deseja enviar a notificação por email/<strong>SMS</strong> ao cliente?<br><strong style='pull-right'>Notificação destinada à</strong>:
  `;

    const result:any = await Swal.fire({
      title: "Atenção",
      html: dataMessage,
      input: 'text',
      inputValue: this.formSMS.value.send_notification_name,
      inputPlaceholder: 'Notificação destinada à:',
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não"
    });


    if(result && result.dismiss === 'cancel'){
      return
    }
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
    });

    try {
      let response = this.appointmentSvc.sendSmsToAppointment(this.appointment.id,this.formSMS.value).toPromise()
      Toast.fire({
        icon: "success",
        title: "SMS enviado com sucesso!"
      });
    } catch (error) {
      Swal.fire({
        title: "Erro",
        html: `
        Não foi possível enviar o SMS!<br>
        Tente novamente, e em caso de persistência entre em contato com o suporte
        `,
        icon: "warning"
      });
    }
  }

  async getDicUser() {
    let userList = await this.userSvc.list({ qtd: -1 }).toPromise();
    let userDic = {};
    userList.data.forEach(element => {
      userDic[element.id] = element;
    });
    return userDic;
  }

  onNoClick(e): void {
    if ((e && e.event == "successCreate") || e.event == "successUpdate") {
      this.dialogRef.close(e);
    }
    let confirmed;

    if (this.manageSchedule.entityForm.touched) {
      let $this = this;
      Swal.fire({
        title: "Atenção!",
        text:
          "Deseja realmente fechar, o formulário pode ter sido alterado e os dados serão perdidos!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo!",
        cancelButtonText: "Não"
      }).then(result => {
        if (result.value) {
          $this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  formatAuditing() {
    let labelDic = {
      id: "ID",
      start: "Inicio horario",
      end: "Fim horario",
      event_date: "Data do eventos",
      notes: "Observação",
      warning_schedule: "Atenção especial",
      guid: "Identificador",
      is_unavailable: "É bloqueio?",
      is_solicitation: "É solicitação/pre-agendamento",
      id_users_provider: "ID consultor",
      id_seamstress: "ID costureira",
      id_customer: "ID cliente",
      id_reasons: "ID motivo",
      id_section: "ID setor",
      id_event_reasons: "ID evento",
      deleted_at: "Data remoção agendamento",
      created_at: "Data criação do agendamento",
      updated_at: "Última atualização",
      customer_name: "Nome do cliente",
      provider_name: "Nome do(a) consultor(a)",
      reason_name: "Motivo",
      color: "Cor identificação",
      event_reason_name: "Evento",
      seamstress_name: "Costureira"
    };

    let booleanList = ["warning_schedule", "is_unavailable", "is_solicitation"];
    let dateList = ["start", "end", "event_date", "created_at", "updated_at"];
    let blackList = ["guid", "id"];
    this.appointment.audits.forEach(elementAudit => {
      const auditingFormat = [];
      let old_value;
      let new_value;

      // tslint:disable-next-line: forin
      for (const key in elementAudit.new_values) {
        if (blackList.includes(key)) {
          continue;
        }

        new_value = "-";
        old_value = "-";
        if (elementAudit.new_values.hasOwnProperty(key)) {
          new_value = elementAudit.new_values[key];
        }
        if (elementAudit.old_values.hasOwnProperty(key)) {
          old_value = elementAudit.old_values[key];
        }

        if (booleanList.includes(key)) {
          new_value = !!elementAudit.new_values[key] ? "Sim" : "Não";
          old_value = !!elementAudit.old_values[key] ? "Sim" : "Não";
        }
        if (dateList.includes(key)) {
          new_value = !!elementAudit.new_values[key]
            ? moment(elementAudit.new_values[key]).format(
                environment.date_time_format_moment
              )
            : "-";
          old_value = !!elementAudit.old_values[key]
            ? moment(elementAudit.old_values[key]).format(
                environment.date_time_format_moment
              )
            : "-";
        }

        auditingFormat.push({
          old_value,
          new_value,
          key,
          label: labelDic[key]
        });
      }

      elementAudit.auditing_format = auditingFormat;
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const dateSelected = this.data.date;
    if (dateSelected && this.manageSchedule) {
      this.manageSchedule.entityForm.controls.start_date.setValue(
        dateSelected.format(environment.date_format_moment)
      );
      this.manageSchedule.entityForm.controls.end_date.setValue(
        dateSelected.format(environment.date_format_moment)
      );
      this.manageSchedule.entityForm.controls.start_time.setValue(
        dateSelected.format("HH:mm")
      );
      this.manageSchedule.entityForm.controls.end_time.setValue(
        dateSelected.add(1, "h").format("HH:mm")
      );
      this.changeDetectorRef.detectChanges();
      this.manageSchedule.entityForm.updateValueAndValidity();
    }
  }

  formAnexo: FormGroup;
  formSMS: FormGroup;
  progress = 0;

  initForm() {
    this.formAnexo = this.formBuilder.group({
      file_send: [null, [Validators.required]],
      appointment_id: [null, [Validators.required]]
    });
  }

  initFormSms() {
    this.formSMS = this.formBuilder.group({
      send_notification_name: [null, [Validators.required]],
      message: [null, [Validators.required]]
    });
  }

  async removeAttachment(attachment) {
    console.log(attachment);

    attachment.in_removed = true;
    const dataMessage = `
      Deseja remover o anexo?
    `;

    const result = await Swal.fire({
      title: "Atenção",
      html: dataMessage,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não"
    });

    if (!!result.value) {
      this.attachmentSvc.delete(attachment.id).subscribe(
        response => {
          attachment.in_removed = false;
          this.Toast.fire({
            icon: "success",
            title: "Anexo removido com sucesso!"
          });
          this.dialogRef.close();
        },
        error => {
          attachment.in_removed = false;
        }
      );
    }
  }

  sendAnexo() {
    // this.httpSvc
    // .post(
    //   `http://api.lenaatelie.com.br/public/api/v1/attachments`,
    //     ,

    //   )
    this.attachmentSvc
      .create(toFormData(this.formAnexo.getRawValue()), {
        reportProgress: true,
        observe: "events"
      })
      .subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round((100 * event.loaded) / event.total);
            if(this.progress >= 100){
              this.savingFile = true;
            }
          }

          if (event.type === HttpEventType.Response) {
            this.progress = 0;
            this.savingFile = false;
            this.Toast.fire({
              icon: "success",
              title: "Anexo enviado com sucesso!"
            });
            this.dialogRef.close();
            this.formAnexo.reset();
          }
        },
        () => {
          Swal.fire({
            title: "Erro",
            html: `
          'Não foi possível enviar anexo!<br>
          Tente novamente, e em caso de persistência entre em contato com o suporte'
          `,
            icon: "warning"
          });
        }
      );
  }
}
