import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { Appointment } from '../models/appointment.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Serializer } from '../models/serializer.model';
import { of, Observable } from 'rxjs';
declare var moment: any;
@Injectable({
  providedIn: 'root'
})
export class BackupService {

  url: string
  version: string
  endpoint: string
    constructor(
      public httpClient: HttpClient
    ) {
        this.url = environment.apis.info_register_api;
        this.version = 'v1';
        this.endpoint = 'backups';
  }


  generateBackup(): Observable<any> {
    return this.httpClient.get(`${this.url}/${this.version}/${this.endpoint}/generate`);
  }






}
