import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Serializer } from '../models/serializer.model';
import { ResourceService } from './resource.service';
import { Reason } from '../models/reason.model';
@Injectable({
  providedIn: 'root'
})
export class ReasonService extends ResourceService<Reason> {

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.info_register_api,
      'v1',
      'reasons',
      new Serializer<Reason>(new Reason)
    )
  }



}
