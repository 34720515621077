import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Serializer } from '../models/serializer.model';
import { ResourceService } from './resource.service';
import { EventReason } from '../models/event-reason.model';
@Injectable({
  providedIn: 'root'
})
export class EventReasonService extends ResourceService<EventReason> {

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.info_register_api,
      'v1',
      'event-reasons',
      new Serializer<EventReason>(new EventReason)
    )
  }



}
