
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import Swal from 'sweetalert2'

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {



  constructor(private auth: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.auth.check() && this.validatePermissions(route)) {
      return true;
    }

    this.router.navigate(['auth/login']);
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.check() && this.validatePermissions(route)) {
      return true;
    }

    this.router.navigate(['auth/login']);
    return false;
  }

  validatePermissions(route): boolean {

    let user = this.auth.getUser();

    if (!!!user) {
      return false;
    }

    if (!!!route.data.permissions) {
      return true;
    }

    if(route.data.permissions.includes(user.role)){
      return true;
    }


    Swal.fire("Erro!", "Você não tem permissão para acessar esta página.", "error");

    return false;


  }
}
