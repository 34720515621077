import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScheduleComponent } from './schedule.component';
import { ListComponent } from './list/list.component';
import { CalendarComponent } from './calendar/calendar.component';
import { SchedueleManageComponent } from 'src/app/shared/scheduele-manage/scheduele-manage.component';


const routes: Routes = [
  {
    path: '', component: ScheduleComponent, data: {
      title: 'Servicees'
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'calendar' },
      {
        path: 'list', pathMatch: 'full',
        canActivate: [],
        component: ListComponent, data: {
          title: 'Listagem'
        }
      },
      {
        path: 'list/removed', pathMatch: 'full',
        canActivate: [],
        component: ListComponent, data: {
          title: 'Listagem'
        }
      },
      {
        path: 'calendar', pathMatch: 'full',
        canActivate: [],
        component: CalendarComponent, data: {
          title: 'Calendario'
        }
      },
      {
        path: 'new', pathMatch: 'full',
        canActivate: [],
        component: SchedueleManageComponent, data: {
          title: 'Criação'
        }
      },
      {
        path: 'view/:id', pathMatch: 'full',
        canActivate: [],
        component: SchedueleManageComponent, data: {
          title: 'Visualização'
        }
      },
      {
        path: 'edit/:id', pathMatch: 'full',
        canActivate: [],
        component: SchedueleManageComponent, data: {
          title: 'Alteração'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScheduleRoutingModule { }
