import { Component, OnInit } from '@angular/core';
import { IResource } from 'src/app/core/interfaces/IResouce';
import { ResourceService } from 'src/app/core/services/resource.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

export class BaseManageComponent<T extends IResource> {
  public entitySvc: ResourceService<T>;
  public entityForm: FormGroup;
  public entityObject: T
  public entityId: number
  public isView: boolean
  public inLoading: boolean
  public formBuilder: FormBuilder;
  public router: Router;
  public activatedRoute: ActivatedRoute;

  public successCreateCallback: any;
  public errorCreateCallback: any;
  public successUpdateCallback: any;
  public errorUpdateCallback: any;
  constructor() {

  }

  getData(entityId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.entitySvc.read(entityId).subscribe(
        (response) => {
          this.entityObject = response;
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    });
  }

    // convenience getter for easy access to form fields
  get f() { return this.entityForm.controls; }

  formatToSend(){
    return this.entityForm.value;
  }

  onSubmit() {
    let dataSend = this.formatToSend()
    this.inLoading = true;
    if(!!this.entityId){
      this.entitySvc.update(this.entityId, dataSend).subscribe(
        this.successUpdateCallback,
        this.errorUpdateCallback,
        ()=>{this.inLoading = false}
        )
      }else{
        this.entitySvc.create(dataSend).subscribe(
          this.successCreateCallback,
          this.errorCreateCallback,
          ()=>{this.inLoading = false}
        )
    }
  }
}
