import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScheduleRoutingModule } from './schedule-routing.module';
import { ScheduleComponent } from './schedule.component';
import { ListComponent } from './list/list.component';
import { CalendarComponent } from './calendar/calendar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SchedueleManageComponent } from './manage/manage.component';

@NgModule({
  declarations: [
    ScheduleComponent,
    ListComponent,
    CalendarComponent,
    SchedueleManageComponent

  ],
  imports: [
    CommonModule,
    SharedModule,
    ScheduleRoutingModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ScheduleModule { }
