import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResourceService } from './resource.service';
import { Solicitation } from '../models/solicitation';
import { Serializer } from '../models/serializer.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SolicitationService extends ResourceService<Solicitation> {

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.info_register_api,
      'v1',
      'solicitations',
      new Serializer<Solicitation>(new Solicitation)
    )
  }

  closeSolicitation(id:number): Observable<any>{
    return this.httpClient
          .post<any>(`${this.url}/${this.version}/${this.endpoint}/closeSolicitation`, {id: id})
  }




}
