import { Component, OnInit } from '@angular/core';
import { BaseListComponent } from 'src/app/shared/base-list/base-list.component';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { Appointment } from 'src/app/core/models/appointment.model';
import { environment } from './../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
declare var moment:any;
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends BaseListComponent<Appointment> implements OnInit {
  confirmationDeleteMessage = 'Deseja realmente remover o serviço?'
  successDeleteMessage = 'Serviço removido com sucesso!'
  errorDeleteMessage = 'Erro ao remover serviço!'
  environment = environment;
  filter: string;
  isRemovedList: boolean
  constructor(
    public entitySvc: AppointmentService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super()
   }

  ngOnInit() {
    let filter = {
      include: ['customer'],
      qtd: -1
    }
    var urlSegment: any = this.activatedRoute.url;
    console.log(urlSegment.value)
    if(urlSegment.value[1] && urlSegment.value[1].path == 'removed'){
      console.log(urlSegment.value)
      this.isRemovedList = true;
      filter['removed'] = true;
    }
    this.getData(filter)


  }

  goToCalendar(item){
    let dateString = moment(
      item.start,
    ).format("YYYY-MM-DD");
    window.localStorage.setItem('currentDay', moment(dateString));
    this.router.navigate(['/admin/schedule/calendar']);
  }

  getEntityList(){

    if(
      this.entityList &&
      this.entityList.length === 0 ||
      this.filter == undefined ||
      this.filter.trim() === ''
    ){
      return this.entityList;
    }

    return this.entityList.filter(
      (v) => {

        if(v.toString().toLowerCase().indexOf(this.filter.toLowerCase()) >= 0){
          return true;
        }
        return false
      }
    )
  }

}

