// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  date_time_format: 'dd/MM/yyyy HH:mm:ss',
  date_format: 'dd/MM/yyyy',
  time_format: 'HH:mm:ss',
  date_time_format_moment: 'DD/MM/YYYY HH:mm:ss',
  date_format_server: 'YYYY-MM-DD',
  date_time_format_server: 'YYYY-MM-DD HH:mm:ss',
  time_format_moment: 'HH:mm:ss',
  date_format_moment: 'DD/MM/YYYY',
  step_finished_service: 5,
  mix_pusher_app_key:'3B94A1DF-EEB4-4FEF-B34A-E07F02619597',
  mix_pusher_app_cluster: 'mt1',
  start_work_time: '08:00:00',
  end_work_time: '19:00:00',
  reason_start: 1,
  reason_return: 2,
  reason_take_measures: 11,
  apis: {
    __info_register_api: `https://api.lenaatelie.com.br/public/api`,
    info_register_api: `https://api.lenaatelie.com.br/public/api`,
    _info_register_api: `http://${window.location.hostname}/api_lenaatelie/public/api`
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
