import { IResource } from '../interfaces/IResouce';
import { Resource } from './resource.model';

export class Audit extends Resource implements IResource {


    constructor(objectJson?: any) {
        super(objectJson);
    }

}
