import {
  Component,
  OnInit,
  AfterContentInit,
  ViewEncapsulation,
  OnDestroy
} from "@angular/core";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { ScheduleRegisterComponent } from "../../../shared/dialogs/schedule-register/schedule-register.component";
import { AppointmentService } from "src/app/core/services/appointment.service";
import { Appointment } from "src/app/core/models/appointment.model";
import { Router } from "@angular/router";
import { LegendProvidersComponent } from "src/app/shared/legend-providers/legend-providers.component";
import { AuthService } from "src/app/auth/services/auth.service";

declare var $: any;
declare var moment: any;
declare var Tooltip: any;

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, AfterContentInit, OnDestroy {
  appointmentsList: any | Appointment[];
  calendar: any;
  listUsersSelected = [];
  loader: boolean;
  legendDialogRef: any;
  appointmentDialogRef: any;
  highligth: boolean
  constructor(
    public dialog: MatDialog,
    public router: Router,
    public appointmentSvc: AppointmentService,
    public authSvc: AuthService
  ) { }

  ngOnInit() { }
  async ngAfterContentInit() {
    this.initCalendar();




    this.fillAppointments();
  }

  ngOnDestroy() {
    this.dialog.closeAll()
  }
  async fillAppointments(filterSend?) {
    let defaultDate;
    try {
      let currentDayString = window.localStorage.getItem("currentDay");
      defaultDate = !!currentDayString ? moment(currentDayString) : moment();
    } catch (error) {
      defaultDate = moment();
    }

    let start_date = defaultDate.clone().startOf('month').format('YYYY-MM-DD HH:mm:ss')
    let end_date = defaultDate.clone().add(1,'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    let dataFilter = {
      start_date,
      end_date
    }

    try {
      let filter = {
        qtd: -1,
        ...dataFilter,
        ...filterSend
      };
      this.loader = true;
      this.appointmentsList = await this.appointmentSvc
        .list(filter)
        .toPromise();
      let view;
      this.loader = false;
      if (this.calendar) {
        view = this.calendar.fullCalendar("getView");
      }
      this.handlerEvents(this.appointmentsList.data, view);
    } catch (error) { }
  }

  handlerEvents(appointments: Appointment[], view?) {
    this.calendar.fullCalendar("removeEvents");
    let highlight = this.highligth;

    let eventsToCalendar = appointments.map(item => {
      if (item.is_unavailable) {
        return {
          title: item.notes,
          start: moment(item.start),
          end: moment(item.end),
          backgroundColor: (highlight) ? 'gray' : "red", //yellow
          borderColor: (highlight) ? 'gray' : "red", //yellow
          data: item
        };
      }
      if (item.is_warning) {
        return {
          title: item.notes,
          start: moment(item.start),
          end: moment(item.end),
          backgroundColor: (highlight) ? 'gray' : "yellow", //yellow
          borderColor: (highlight) ? 'gray' : "yellow", //yellow
          data: item,
          textColor: '#000'
        };
      }
      if (item.is_fake) {
        return {
          title: item.customer_name,
          start: moment(item.start),
          end: moment(item.end),
          backgroundColor: (highlight) ? 'gray' : "#ab0000", //yellow
          borderColor: (highlight) ? 'gray' : "#ab0000", //yellow
          data: item
        };
      }

      let color;
      if (!!highlight) {
        if (item.id_reasons != 8 && item.id_event_reasons == 4) {
          color = 'blue';
        } else if (item.id_reasons == 8 && item.id_event_reasons == 4) {
          color = 'red';
        } else {
          color = 'gray';
        }
      } else {
        color = item.color;
      }

      if (view && view.type == "month") {
        return {
          title: item.customer_name,
          start: moment(item.start),
          end: moment(item.end),
          backgroundColor: color, //yellow
          borderColor: color, //yellow
          data: item
        };
      }
      return {
        title: item.toString(),
        start: moment(item.start),
        end: moment(item.end),
        backgroundColor: color, //yellow
        borderColor: color, //yellow
        data: item
      };

      // }
    });

    this.calendar.fullCalendar("addEventSource", eventsToCalendar);
  }

  initCalendar() {
    let $this = this;
    $(function () {
      /* initialize the external events
       -----------------------------------------------------------------*/
      function init_events(ele) {
        ele.each(function () {
          // create an Event Object (http://arshaw.com/fullcalendar/docs/event_data/Event_Object/)
          // it doesn't need to have a start or end
          var eventObject = {
            title: $.trim($(this).text()) // use the element's text as the event title
          };

          // store the Event Object in the DOM element so we can get to it later
          $(this).data("eventObject", eventObject);

          // make the event draggable using jQuery UI
          $(this).draggable({
            zIndex: 1070,
            revert: true, // will cause the event to go back to its
            revertDuration: 0 //  original position after the drag
          });
        });
      }

      init_events($("#external-events div.external-event"));

      /* initialize the calendar
       -----------------------------------------------------------------*/
      //Date for the calendar events (dummy data)
      var date = new Date();
      var d = date.getDate(),
        m = date.getMonth(),
        y = date.getFullYear();
      let defaultView = window.localStorage.getItem("currentView") || "month";
      let defaultDate;
      try {
        let currentDayString = window.localStorage.getItem("currentDay");
        let currentMonthString = window.localStorage.getItem("currentMonth");

        defaultDate = !!currentDayString ? moment(currentDayString) : moment();
      } catch (error) {
        defaultDate = moment();
      }

      $this.calendar = $("#calendar").fullCalendar({
        locale: "pt-br",
        timeZone: "UTC",
        allDaySlot: false,
        defaultView,
        defaultDate,
        slotEventOverlap: false,
        displayEventTime: false,
        eventLimit: 4, // If you set a number it will hide the itens
        eventLimitText: function (data, data2) {
          return `Ver mais (${data + 3})`;
        },
        eventLimitClick: "day",
        header: {
          left: "agendaDay,agendaSixDay,month, thumbnailCalendar",
          center: "title",
          right: "highlight ,search, legend, today, prev,next"
        },
        views: {
          basic: {
            columnHeaderFormat: "dddd"
            // options apply to basicWeek and basicDay views
          },
          agenda: {
            columnHeaderFormat: "DD-MM-YYYY ddd"
            // options apply to agendaWeek and agendaDay views
          },
          month: {
            columnHeaderFormat: "dddd"
            // options apply to basicWeek and agendaWeek views
          },
          week: {
            columnHeaderFormat: "dddd"
            // options apply to basicWeek and agendaWeek views
          },
          day: {
            titleFormat: "DD-MM-YYYY dddd",
            columnHeaderFormat: "DD-MM-YYYY"
            // options apply to basicDay and agendaDay views
          },
          agendaSixDay: {
            type: "agenda",
            dayCount: 5,
            duration: {
              days: 7
            },
            intervalStart: 1,
            title: "Apertura",
            hiddenDays: [0], // Hide Sunday and Saturday?
            buttonText: "Semana"
          }
        },
        viewRender: function (view, element) {
          console.log(view)
          let currentMonth = window.localStorage.getItem("currentMonth")
          let refreshEvents = false;
          if(currentMonth != moment(view.start).format("MM")){
            refreshEvents = true;
          }
          window.localStorage.setItem("currentDay",moment(view.start).format("YYYY-MM-DD"));
          window.localStorage.setItem("currentMonth",moment(view.start).format("MM"));

          if(refreshEvents){
            $this.fillAppointments()
          }


          window.localStorage.setItem("currentView", view.type);
          if (!$this.calendar) {
            return;
          }

          if (view.type == "agendaSixDay") {
            var date = $this.calendar.fullCalendar("getDate");
            $this.calendar.fullCalendar("gotoDate", date.startOf("week"));
          }

          if (view.type == "agendaDay") {
            $(`.fc-${view.type}-view .fc-head`).remove();
            $this.timeClickRegisterEvent();
          }

          var searchButton = $(".fc-search-button");
          var highLightButton = $(".fc-highlight-button");
          $this.initThumbCalendar();

          $(searchButton).html(`<i class="fa fa-search"></i> Buscar`);
          $(highLightButton).html(`<i class="fa fa-braille"></i> Destacar`);

          $this.renderFilterButton();

          if (view.type == "month") {
            $this.calendar.fullCalendar("option", "contentHeight", 550);
          } else {
            $this.calendar.fullCalendar("option", "contentHeight", "auto");
          }

          $this.initDatepicker();
          $this.handlerEvents($this.appointmentsList.data, view);
        },
        firstDay: 1,
        minTime: environment.start_work_time,
        maxTime: environment.end_work_time,
        slotDuration: "01:00:00",
        contentHeight: 550,
        slotLabelInterval: {
          hours: 1
        },
        slotLabelFormat: [
          "MMMM YYYY", // top level of text
          "HH:mm" // lower level of text
        ],
        businessHours: [
          // specify an array instead
          {
            dow: [1, 2, 3, 4, 5], // Monday, Tuesday, Wednesday
            start: "08:00", // 8am
            end: "19:00" // 6pm
          },
          {
            dow: [6], // Thursday, Friday
            start: "08:00", // 10am
            end: "13:00" // 4pm
          }
        ],
        selectConstraint: "businessHours",
        customButtons: {
          highlight: {
            text: "Destacar",
            click: function () {
              let view;
              if ($this.calendar) {
                view = $this.calendar.fullCalendar("getView");
              }
              $this.highligth = !$this.highligth;
              if($this.highligth){
                $(".fc-highlight-button").addClass('fc-state-active');
              }else{
                $(".fc-highlight-button").removeClass('fc-state-active');
              }
              $this.handlerEvents($this.appointmentsList.data, view);
            }
          },
          search: {
            text: "Buscar",
            click: function () {
              $this.router.navigate(["/admin/schedule/list"]);
            }
          },
          legend: {
            text: "Filtro",
            click: function () {
              $this.openLegend();
            }
          },
          thumbnailCalendar: {
            text: "",
            click: function () { }
          }
        },
        buttonText: {
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
          listWeek: "Lista"
        },
        navLinks: true,
        navLinkDayClick: function (date, jsEvent) {
          let currentMonth = window.localStorage.getItem("currentMonth")
          let refreshEvents = false;
          if(currentMonth != moment(date).format("MM")){
            refreshEvents = true;
          }
          window.localStorage.setItem("currentDay",moment(date).format("YYYY-MM-DD"));
          window.localStorage.setItem("currentMonth",moment(date).format("MM"));

          if(refreshEvents){
            $this.fillAppointments()
          }
          $this.calendar.fullCalendar("changeView", "agendaDay", date);
        },
        dayClick: function (date, jsEvent, view) {
          window.localStorage.setItem("currentDay",moment(date).format("YYYY-MM-DD"));
          window.localStorage.setItem("currentMonth",moment(date).format("MM"));
          if (jsEvent.target.className == "fc-nonbusiness fc-bgevent") {
            return false;
          }
          if (view.type == "month") {
            $this.calendar.fullCalendar("changeView", "agendaDay", date);
            return;
          }

          $this.openDialog(date);
        },
        eventClick: function (calEvent, jsEvent, view) {
          $this.openDialog(null, calEvent.data);
        },
        eventRender: function (event, element) {
          if (event.data.warning_schedule == true) {
            element
              .find(".fc-time")
              .prepend('<i class="fa fa-exclamation-triangle"></i> ');
          }
        },
        eventMouseover: function (event, jsEvent, view) {
          // var eventContainer = $(this).parent('.fc-event-container');
          var eventContainer = $("body");

          if (view.type == "month") {
            var templatePopover = `
          <div class='popover-event'>
            <div class="warning-indication"><i class="fa fa-exclamation-triangle"></i> Atenção ao agendamento</div>
            <div><strong>Horário:</strong> ${moment(event.data.start).format(
              environment.date_time_format_moment
            )} </div>
            <div><strong>Nome:</strong> ${event.data.customer_name} </div>
            <div><strong>Motivo:</strong> ${event.data.reason_name} </div>
            <div><strong>Consultor:</strong> ${event.data.provider_name} </div>
            <div><strong>Evento:</strong> ${event.data.event_reason_name} </div>
            <div><strong>Data do evento:</strong> ${moment(
              event.data.event_date
            ).format(environment.date_format_moment)} </div>
            <div><strong>Observação:</strong> ${event.data.notes || "-"} </div>
          </div>
          `;
            var popOver = $(eventContainer).append(templatePopover);

            if (event.data.warning_schedule == false) {
              $(popOver)
                .find(".warning-indication")
                .remove();
            }

            var offsets = $(this).offset();
            var top, left;

            top = offsets.top + 25;
            left = offsets.left + 10;

            // else {
            //   top = offsets.top + 58;
            //   left = offsets.left + 10;
            // }

            $(".popover-event").css("top", top + "px");
            $(".popover-event").css("left", left + "px");
          }
        },
        eventMouseout: function (event, jsEvent, view) {
          var eventContainer = $(this).parent(".fc-event-container");
          $("body")
            .find(".popover-event")
            .remove();
        },
        //Random default events
        events: [],
        timeFormat: "HH:mm",
        editable: false,
        droppable: false, // this allows things to be dropped onto the calendar !!!
        drop: function (date, allDay) {
          // this function is called when something is dropped

          // retrieve the dropped element's stored Event Object
          var originalEventObject = $(this).data("eventObject");

          // we need to copy it, so that multiple events don't have a reference to the same object
          var copiedEventObject = $.extend({}, originalEventObject);

          // assign it the date that was reported
          copiedEventObject.start = date;
          copiedEventObject.allDay = allDay;
          copiedEventObject.backgroundColor = $(this).css("background-color");
          copiedEventObject.borderColor = $(this).css("border-color");

          // render the event on the calendar
          // the last `true` argument determines if the event "sticks" (http://arshaw.com/fullcalendar/docs/event_rendering/renderEvent/)
          $("#calendar").fullCalendar("renderEvent", copiedEventObject, true);

          // is the "remove after drop" checkbox checked?
          if ($("#drop-remove").is(":checked")) {
            // if so, remove the element from the "Draggable Events" list
            $(this).remove();
          }
        }
      });

      var searchButton = $(".fc-search-button");
      var highLightButton = $(".fc-highlight-button");
      setTimeout(() => {
        $(`.fc-agendaDay-view .fc-head`).remove();
      }, 500);

      $(searchButton).html(`<i class="fa fa-search"></i> Buscar`);
      $(highLightButton).html(`<i class="fa fa-braille"></i> Destacar`);
      $this.renderFilterButton();
      $this.initThumbCalendar();
      $this.timeClickRegisterEvent();

      /* ADDING EVENTS */
      var currColor = "#3c8dbc"; //Red by default
      //Color chooser button
      var colorChooser = $("#color-chooser-btn");
      $("#color-chooser > li > a").click(function (e) {
        e.preventDefault();
        //Save color
        currColor = $(this).css("color");
        //Add color effect to button
        $("#add-new-event").css({
          "background-color": currColor,
          "border-color": currColor
        });
      });
      $("#add-new-event").click(function (e) {
        e.preventDefault();
        //Get value and make sure it is not null
        var val = $("#new-event").val();
        if (val.length == 0) {
          return;
        }

        //Create events
        var event = $("<div />");
        event
          .css({
            "background-color": currColor,
            "border-color": currColor,
            color: "#fff"
          })
          .addClass("external-event");
        event.html(val);
        $("#external-events").prepend(event);

        //Add draggable funtionality
        init_events(event);

        //Remove event from text input
        $("#new-event").val("");
      });
    });
  }

  renderFilterButton() {
    var $this = this;
    var legendButton = $(".fc-legend-button");
    $(legendButton).html(`<i class="fa fa-user"></i> Filtro`);
    if ($this.listUsersSelected.length > 0) {
      $(legendButton).append(
        `<span class="badge bg-yellow badge-filter">${$this.listUsersSelected.length}</span>`
      );
    }
  }

  initThumbCalendar() {
    let $this = this;
    let elementhtml = `
    <div class="input-group date">
        <input type="hidden" class="form-control" value="${moment().format(
      "DD-MM-YYYY"
    )}">
        <div class="input-group-addon">
            <span class="fa fa-calendar"></span>
        </div>
    </div>`;

    var thumbnailCalendarButton = $(".fc-thumbnailCalendar-button");
    $(thumbnailCalendarButton).html(elementhtml);

    setTimeout(() => {
      $this.initDatepicker();
    }, 300);
  }

  openLegend(): void {
    let $this = this;
    this.legendDialogRef = this.dialog.open(LegendProvidersComponent, {
      autoFocus: false,
      width: "320px",
      data: {
        listUsersSelected: $this.listUsersSelected
      }
    });

    this.legendDialogRef.afterClosed().subscribe(result => {
      // this.calendar.fullCalendar("removeEvents");

      if (result) {
        if (result == "close") {
          return;
        }
        if (result.length > 0) {
          let filter;
          filter = {
            id_users_provider: result
          };
          this.fillAppointments(filter);
        } else {
          this.fillAppointments();
        }
        $this.listUsersSelected = [...result];
        $this.renderFilterButton();
      }
    });
  }

  initDatepicker() {
    let $this = this;
    $(".fc-thumbnailCalendar-button .date")
      .datepicker({
        autoclose: true,
        daysOfWeekDisabled: [0],
        language: "pt-BR"
      })
      .on("changeDate", function (e) {
        $this.calendar.fullCalendar("changeView", "agendaDay", e.date);
        // $this.calendar.fullCalendar( 'gotoDate', e.date);
      });
  }

  openDialog(dateSelected, appointment = null): void {
    if (!!!this.authSvc.getUser()) {
      this.router.navigate(["/auth/login"]);
      return;
    }
    this.appointmentDialogRef = this.dialog.open(ScheduleRegisterComponent, {
      autoFocus: false,
      width: "75%",
      data: {
        date: dateSelected,
        appointment
      }
    });

    this.appointmentDialogRef.afterClosed().subscribe(result => {
      this.fillAppointments();
    });
  }

  timeClickRegisterEvent() {
    let $this = this;
    $(".fc-time").on("click", e => {
      var timeString = $(e.target)
        .parent()
        .closest("tr")
        .attr("data-time");
      var date = $this.calendar.fullCalendar("getDate");
      var options = $this.calendar.fullCalendar("option", "businessHours");
      var dateToGo = `${date.format(
        environment.date_format_server
      )} ${timeString}`;
      var dateToGoMoment = moment(
        dateToGo,
        environment.date_time_format_server
      );
      var dayOfweek = date.day();

      var validHour = false;
      options.forEach(element => {
        if (element.dow.includes(dayOfweek)) {
          var startDateTime = `${date.format(environment.date_format_server)} ${element.start
            }`;
          var endDateTime = `${date.format(environment.date_format_server)} ${element.end
            }`;
          if (
            dateToGoMoment.isBetween(startDateTime, endDateTime, null, "[)")
          ) {
            validHour = true;
          }
        }
      });

      if (validHour) {
        $this.openDialog(dateToGoMoment);
      }
      // .set(timeString, 'HH:mm:ss');
      // $this.openDialog()
    });
  }
}
