import { Customer } from './../../core/models/customer.model';
import { AuditService } from "./../../core/services/audit.service";
import * as VMasker from 'vanilla-masker';
import {
  Component,
  OnInit,
  AfterContentInit,
  Input,
  EventEmitter,
  Output
} from "@angular/core";
import { BaseManageComponent } from "src/app/shared/base-manage/base-manage.component";
import { FormBuilder, Validators, FormGroup, AbstractControl, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AppointmentService } from "src/app/core/services/appointment.service";
import { Appointment } from "src/app/core/models/appointment.model";
import { environment } from "src/environments/environment";
import { UserService } from "src/app/core/services/user.service";
import { User } from "src/app/core/models/user.model";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { CustomerService } from "src/app/core/services/customer.service";
import { Seamstress } from "src/app/core/models/seamstress.model";
import { Reason } from "src/app/core/models/reason.model";
import { ReasonService } from "src/app/core/services/reason.service";
import { EventReason } from "src/app/core/models/event-reason.model";
import { EventReasonService } from "src/app/core/services/event-reason.service";
import { SeamstressService } from "src/app/core/services/seamstress.service";
// import Swal from 'sweetalert2'
declare var $: any;
declare var moment: any;
declare var Swal: any;

@Component({
  selector: "scheduele-manage",
  templateUrl: "./scheduele-manage.component.html",
  styleUrls: ["./scheduele-manage.component.scss"]
})
export class SchedueleManageComponent extends BaseManageComponent<Appointment>
  implements OnInit, AfterContentInit {
  environment = environment;
  public entityForm: FormGroup;
  public entityId: number;
  public entityObject: Appointment;
  public isView: boolean;
  public isUnavailable: boolean;
  public reasonsList: Reason[];
  public eventReasonsList: EventReason[];
  public usersList: User[];
  public seamstressList: Seamstress[];
  public sessionList = [
    { id: 1, name: "Venda" },
    { id: 2, name: "Locação" },
    { id: 3, name: "Venda e Locação" }
  ];
  public eventDic = {
    created: "Criado",
    updated: "Atualizado",
    deleted: "Removido"
  };

  @Input() insideDialog: boolean = false;
  @Input() disabled: boolean;
  @Input() data: any;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();

  successCreateCallback = function (response) {
    let $this = this;
    Swal.fire("Sucesso", "O agendamento foi registrado!", "success").then(
      () => {
        if ($this.insideDialog) {
          $this.entityForm.reset();
          $this.closeDialog.emit({
            event: "successCreate",
            response,
            data: $this.entityForm.value
          });
        } else {
          $this.router.navigate(["/admin/schedule/calendar"]);
        }
      }
    );
  }.bind(this);

  successUpdateCallback = function (response) {
    let $this = this;
    Swal.fire("Sucesso", "O agendamento foi registrado!", "success").then(
      () => {
        if ($this.insideDialog) {
          $this.entityForm.reset();
          $this.closeDialog.emit({
            event: "successUpdate",
            response,
            data: $this.entityForm.value
          });
        } else {
          $this.router.navigate(["/admin/schedule/calendar"]);
        }
      }
    );
  }.bind(this);

  errorCreateCallback = function (errorResponse) {
    var errorMessage;
    if (
      errorResponse &&
      errorResponse.status == 422 &&
      errorResponse.error.message
    ) {
      errorMessage = errorResponse.error.message;
    } else {
      errorMessage = `
        Não foi possivel registrar o agendamento!
        Tente novamente, e em caso de persistência entre em contato com o suporte.
      `;
    }

    let $this = this;
    Swal.fire("Atenção!", errorMessage, "error").then(() => {
      $this.inLoading = false;
    });
  }.bind(this);

  errorUpdateCallback = function (errorResponse) {
    var errorMessage;
    if (
      errorResponse &&
      errorResponse.status == 422 &&
      errorResponse.error.message
    ) {
      errorMessage = errorResponse.error.message;
    } else {
      errorMessage = `
        Não foi possivel atualizar o agendamento!
        Tente novamente, e em caso de persistência entre em contato com o suporte.
      `;
    }

    let $this = this;
    Swal.fire("Atenção!", errorMessage, "error").then(() => {
      $this.inLoading = false;
    });
  }.bind(this);

  constructor(
    public entitySvc: AppointmentService,
    public reasonSvc: ReasonService,
    public seamstressSvc: SeamstressService,
    public eventReasonSvc: EventReasonService,
    public userSvc: UserService,
    public customerSvc: CustomerService,
    public formBuilder: FormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public auditSvc: AuditService
  ) {
    super();
  }

  ngOnInit() {
    if (this.insideDialog) {
      this.initInDialog();
    } else {
      this.initRoute();
    }
  }

  initRoute() {
    this.entityId = +this.activatedRoute.snapshot.params.id;
    this.isView = this.activatedRoute.snapshot.routeConfig.path == "view/:id";

    this.initForm();
    if (!!this.entityId) {
      this.getData(this.entityId).then(response => {
        if (response && response.deleted_at) {
          this.formatAuditing();
        }
        this.fillForm(this.entityObject);
      });
    } else {
      const dateNow = moment();
      this.entityForm.controls.start_date.setValue(
        dateNow.format(environment.date_format_moment)
      );
      this.entityForm.controls.end_date.setValue(
        dateNow.format(environment.date_format_moment)
      );
      this.entityForm.controls.start_time.setValue(dateNow.format("HH:mm"));
      this.entityForm.controls.end_time.setValue(
        dateNow.add(1, "h").format("HH:mm")
      );
    }
    this.getReasons();
    this.getSeamstress();
    this.getEventsReasons();
    this.getUsersProvider();
  }

  initInDialog() {
    this.initForm();
    if (!!this.data) {
      this.entityId = +this.data.id;
    }
    this.isView = this.disabled || false;

    if (!!this.entityId) {
      this.getData(this.entityId).then(response => {
        if (response && response.deleted_at) {
          this.formatAuditing();
        }
        this.fillForm(this.entityObject);
      });
    }
    this.getReasons();
    this.getSeamstress();
    this.getEventsReasons();
    this.getUsersProvider();
  }

  formatAuditing() {
    console.log("formatAuditing");
    let labelDic = {
      id: "ID",
      start: "Inicio horario",
      end: "Fim horario",
      event_date: "Data do eventos",
      notes: "Observação",
      warning_schedule: "Atenção especial",
      is_fake: "Horário fake",
      guid: "Identificador",
      is_unavailable: "É bloqueio?",
      is_solicitation: "É solicitação/pre-agendamento",
      id_users_provider: "ID consultor",
      id_seamstress: "ID costureira",
      id_customer: "ID cliente",
      id_reasons: "ID motivo",
      id_section: "ID setor",
      id_event_reasons: "ID evento",
      deleted_at: "Data remoção agendamento",
      created_at: "Data criação do agendamento",
      updated_at: "Última atualização",
      customer_name: "Nome do cliente",
      provider_name: "Nome do(a) consultor(a)",
      reason_name: "Motivo",
      color: "Cor identificação",
      event_reason_name: "Evento",
      seamstress_name: "Costureira"
    };

    let booleanList = ["warning_schedule", "is_unavailable", "is_solicitation","is_fake"];
    let dateList = ["start", "end", "event_date", "created_at", "updated_at"];
    let blackList = ["guid", "id"];
    this.auditSvc.formatAuditing(
      this.entityObject.audits,
      labelDic,
      booleanList,
      dateList,
      blackList
    );
    console.log(this.entityObject);
  }

  ngAfterContentInit() {
    this.initForm();
    let $this = this;
    setTimeout(() => {
      $(".datepicker")
        .datepicker({
          autoclose: true,
          daysOfWeekDisabled: [0],
          language: "pt-BR"
        })
        .on("changeDate", function (e) {
          let controlName = e.target.attributes.formcontrolname.value;

          if (controlName == "start_date" || controlName == "end_date") {
            $this.entityForm.controls["start_date"].setValue(
              moment(e.date, environment.date_format_moment).format(
                environment.date_format_moment
              )
            );
            $this.entityForm.controls["end_date"].setValue(
              moment(e.date, environment.date_format_moment).format(
                environment.date_format_moment
              )
            );
          } else {
            $this.entityForm.controls[controlName].setValue(
              moment(e.date, environment.date_format_moment).format(
                environment.date_format_moment
              )
            );
          }
        });
    }, 300);
  }

  setRange(data) {
    this.entityForm.controls.start = data.start;
    this.entityForm.controls.end = data.end;
    this.entityForm.updateValueAndValidity();
  }

  fillForm(entityObject) {
    // this.f.start_date.setValue(moment(entityObject.start).format('DD/MM/YYYY'));
    // this.f.end_date.setValue(moment(entityObject.end).format('DD/MM/YYYY'));

    this.f.start_time.setValue(moment(entityObject.start).format("HH:mm"));
    this.f.end_time.setValue(moment(entityObject.end).format("HH:mm"));
    this.f.warning_schedule.setValue(entityObject.warning_schedule);
    this.f.is_fake.setValue(entityObject.is_fake);

    setTimeout(() => {
      $('.datepicker[formcontrolname="start_date"]').datepicker(
        "setDate",
        new Date(entityObject.start)
      );
      $('.datepicker[formcontrolname="end_date"]').datepicker(
        "setDate",
        new Date(entityObject.end)
      );
      $('.datepicker[formcontrolname="event_date"]').datepicker(
        "setDate",
        moment(entityObject.event_date).toDate()
      );
    }, 100);

    this.f.notes.setValue(entityObject.notes);
    this.f.is_unavailable.setValue(entityObject.is_unavailable);
    this.updateFormValidationWhenChangeIsUnavailable(entityObject.is_unavailable)
    this.f.is_warning.setValue(entityObject.is_warning);
    this.updateFormValidationWhenChangeIsUnavailable(entityObject.is_warning)
    this.f.is_solicitation.setValue(entityObject.is_solicitation);
    this.f.id_customer.setValue(entityObject.id_customer);
    this.f.id_users_provider.setValue(entityObject.id_users_provider);
    this.f.id_seamstress.setValue(entityObject.id_seamstress);
    this.f.id_reasons.setValue(entityObject.id_reasons);
    this.f.id_section.setValue(entityObject.id_section);
    this.f.id_event_reasons.setValue(entityObject.id_event_reasons);
    // this.f.event_date.setValue(entityObject.event_date);

    if (entityObject.customer) {
      let customerGroup = this.f.customer as FormGroup;
      customerGroup.controls.name.setValue(entityObject.customer.name);
      if (!!entityObject.customer.code && entityObject.customer.code != 'null') {
        entityObject.customer.code = String(entityObject.customer.code).replace('V', '').replace('L', '').replace('VL', '');
        customerGroup.controls.code.setValue(entityObject.customer.code, {
          emitEvent: false,
          onlySelf: true
        });
      }

      if (!!!entityObject.customer.email) {
        customerGroup.controls.hasEmail.setValue(false);
      }
      customerGroup.controls.email.setValue(entityObject.customer.email);


      if (entityObject.customer.phone1) {
        const text = (entityObject.customer.phone1).replace(/[_\W]+/g, '');
        const mask = (text.length < 11) ? '(99) 9999-9999?9' : '(99) 9 9999-9999';

        customerGroup.controls.phone1.setValue(text);
        if (!!$('[formControlName="phone1"]')[0]) {
          VMasker($('[formControlName="phone1"]')[0]).maskPattern(mask);
        }
      }
      customerGroup.controls.phone2.setValue(entityObject.customer.phone2);
    }

    if (this.isView) {
      this.entityForm.disable();
    }

    this.validateSectionType()

    this.f.is_unavailable.updateValueAndValidity();
    this.f.is_solicitation.updateValueAndValidity();
    this.f.id_customer.updateValueAndValidity();
    this.f.id_users_provider.updateValueAndValidity();
    this.f.id_seamstress.updateValueAndValidity();
    this.f.id_reasons.updateValueAndValidity();
    this.f.id_section.updateValueAndValidity();
    this.f.id_event_reasons.updateValueAndValidity();
    this.f.event_date.updateValueAndValidity();

    let customerGroup = this.f.customer as FormGroup;
    customerGroup.controls.name.updateValueAndValidity();
    customerGroup.controls.code.updateValueAndValidity({ emitEvent: false });
    customerGroup.controls.email.updateValueAndValidity();
    customerGroup.controls.phone1.updateValueAndValidity();
    customerGroup.controls.phone2.updateValueAndValidity();
  }

  getReasons() {
    const filter = {
      qtd: -1
    };
    this.reasonSvc.list(filter).subscribe(response => {
      this.reasonsList = response.data;
    });
  }

  validateSectionType() {
    const sessionControl = this.f.id_section;
    const customerControl = this.f.customer as FormGroup;
    customerControl.controls.code.disable()
    if (!!sessionControl.value && ['1', '2'].includes(sessionControl.value)) {
      customerControl.controls.code.enable()
    }
    if (sessionControl.value == '3') {
      customerControl.controls.code.setValue(null)
    }
  }

  getEventsReasons() {
    const filter = {
      qtd: -1
    };
    this.eventReasonSvc.list(filter).subscribe(response => {
      this.eventReasonsList = response.data;
    });
  }

  getSeamstress() {
    const filter = {
      qtd: -1
    };
    this.seamstressSvc.list(filter).subscribe(response => {
      this.seamstressList = response.data;
    });
  }

  getUsersProvider() {
    const filter = {
      status: 1,
      qtd: -1,
      roles: ["consultant"]
    };
    this.userSvc.list(filter).subscribe(response => {
      this.usersList = response.data;
    });
  }

  getMask() {
    return "(99)9999-99999";
  }

  initForm() {
    this.entityForm = this.formBuilder.group({
      start_date: [null, [Validators.required]],
      start_time: [null, [Validators.required]],
      end_date: [null, [Validators.required]],
      end_time: [null, [Validators.required]],
      notes: [null],
      is_unavailable: [null],
      is_warning: [null],
      is_solicitation: [null],
      warning_schedule: [null],
      is_fake: [null],
      id_customer: [null],
      id_users_provider: [null, [Validators.required]],
      id_seamstress: [null],
      id_seamstress_provider: [null],
      id_section: [null, [Validators.required]],
      id_reasons: [null, [Validators.required]],
      id_event_reasons: [null, [Validators.required]],
      event_date: [null, [Validators.required]],
      customer: this.formBuilder.group({
        code: [null, [Validators.required]],
        name: [null, [Validators.required]],
        hasEmail: [true, [Validators.required]],
        email: [null, [Validators.required]],
        phone1: [null, [Validators.required]],
        phone2: [null]
      })
    });

    this.entityForm.controls.id_reasons.valueChanges.subscribe(newValue => {
      this.updateCodeInputRolesWhenChangeReason(newValue)
    });

    let customerGroup = this.entityForm.controls.customer as FormGroup;
    // customerGroup.controls.code.valueChanges
    //   .pipe(debounceTime(500))
    //   .subscribe(async value => {
    //     this.setCustomerDataByCode(value + "");
    //   });
    customerGroup.controls.hasEmail.valueChanges
      .subscribe(async value => {
        if (value == true) {
          customerGroup.controls.email.setValidators([Validators.required])
          customerGroup.controls.email.enable()
        } else {
          customerGroup.controls.email.clearValidators()
          customerGroup.controls.email.setValue(null)
          customerGroup.controls.email.disable()
        }
        customerGroup.controls.email.updateValueAndValidity()
      });
    this.entityForm.controls.is_unavailable.valueChanges.subscribe(values => this.updateFormValidationWhenChangeIsUnavailable(values));
    this.entityForm.controls.is_warning.valueChanges.subscribe(values => this.updateFormValidationWhenChangeIsUnavailable(values));
    this.entityForm.markAsPristine();
    this.entityForm.markAsDirty();
    this.entityForm.markAsUntouched();
    this.validateSectionType()
  }

  updateFormValidationWhenChangeIsUnavailable(values) {
    console.log(values)
    this.isUnavailable = this.entityForm.controls.is_unavailable.value || this.entityForm.controls.is_warning.value;
    this.removeValidators(this.entityForm);
    let customerGroup = this.f.customer as FormGroup;
    this.removeValidators(customerGroup);

    if (!!this.isUnavailable) {
      this.f.notes.setValidators([Validators.required]);
    } else {
      this.f.id_customer.setValidators([Validators.required]);
      // this.f.id_users_provider.setValidators([Validators.required]);
      this.f.id_seamstress.setValidators([]);
      this.f.id_reasons.setValidators([Validators.required]);
      this.f.id_section.setValidators([Validators.required]);
      this.f.id_event_reasons.setValidators([Validators.required]);
      this.f.event_date.setValidators([Validators.required]);

      this.f.id_customer.updateValueAndValidity();
      // this.f.id_users_provider.updateValueAndValidity();
      this.f.id_seamstress.updateValueAndValidity();
      this.f.id_reasons.updateValueAndValidity();
      this.f.id_section.updateValueAndValidity();
      this.f.id_event_reasons.updateValueAndValidity();
      this.f.event_date.updateValueAndValidity();

      let customerGroup = this.f.customer as FormGroup;
      customerGroup.controls.name.setValidators([Validators.required]);
      if (this.f.id_reasons.value != environment.reason_start) {
        customerGroup.controls.code.setValidators([Validators.required]);
      } else {
        customerGroup.controls.code.clearValidators();
      }
      customerGroup.controls.email.setValidators([Validators.required]);
      customerGroup.controls.phone1.setValidators([Validators.required]);

      customerGroup.controls.name.updateValueAndValidity();
      customerGroup.controls.code.updateValueAndValidity({
        emitEvent: false
      });
      customerGroup.controls.email.updateValueAndValidity();
      customerGroup.controls.phone1.updateValueAndValidity();
    }

    this.f.start_date.setValidators([Validators.required]);
    this.f.start_time.setValidators([Validators.required]);
    this.f.end_date.setValidators([Validators.required]);
    this.f.end_time.setValidators([Validators.required]);

    this.f.start_date.updateValueAndValidity();
    this.f.start_time.updateValueAndValidity();
    this.f.end_date.updateValueAndValidity();
    this.f.end_time.updateValueAndValidity();
  }

  getFormErrors(form: AbstractControl) {
    if (form instanceof FormControl) {
      // Return FormControl errors or null
      return form.errors ? form.errors : null;
    }
    if (form instanceof FormGroup) {
      const groupErrors = form.errors;
      // Form group can contain errors itself, in that case add'em
      const formErrors = groupErrors ? { groupErrors } : {};
      Object.keys(form.controls).forEach(key => {
        // Recursive call of the FormGroup fields
        const error = this.getFormErrors(form.get(key));
        if (error !== null) {
          // Only add error if not null
          formErrors[key] = error;
        }
      });
      // Return FormGroup errors or null
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }

  updateCodeInputRolesWhenChangeReason(newValue) {
    newValue = +newValue;
    let customerGroup = this.f.customer as FormGroup;
    if (
      newValue != environment.reason_start &&
      newValue != environment.reason_return &&
      newValue != environment.reason_take_measures &&
      this.f.is_unavailable.value != true
    ) {

      this.entityForm.controls.id_users_provider.setValidators([
        Validators.required
      ]);

      if (customerGroup.controls.code.value == 'null') {
        customerGroup.controls.code.setValue(null)
      }

      customerGroup.controls.code.setValidators([Validators.required]);
    } else {
      this.entityForm.controls.id_users_provider.clearValidators();
      customerGroup.controls.code.clearValidators();
      customerGroup.controls.code.setValue(null)
    }
    this.entityForm.controls.id_users_provider.updateValueAndValidity({
      emitEvent: false
    });
    customerGroup.controls.code.updateValueAndValidity({ emitEvent: false });
  }

  public removeValidators(form: FormGroup) {
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).updateValueAndValidity({ emitEvent: false });
    }
  }

  // public addValidators(form: FormGroup) {
  //         for (const key in form.controls) {
  //              form.get(key).setValidators(this.validationType[key]);
  //              form.get(key).updateValueAndValidity();
  //         }

  async setCustomerDataByCode(code) {
    if (code == 'null') {
      return;
    }
    let customerGroup = this.entityForm.controls.customer as FormGroup;
    var idSection = this.entityForm.controls.id_section.value + '';
    var newCode = code.replace('V', '').replace('L', '').replace('VL', '');

    switch (idSection) {
      case '1':
        newCode = newCode + 'V';
        break;
      case '2':
        newCode = newCode + 'L';
        break;
      case '3':
        newCode = newCode + 'VL';
        break;
    }
    console.log(newCode)
    let customer: any = await this.customerSvc.getByCode(newCode).toPromise();
    if (!!customer) {
      if (!!customer.name) {
        customerGroup.controls.name.setValue(customer.name);
      }
      if (!!customer.email) {
        customerGroup.controls.hasEmail.setValue(true);
        customerGroup.controls.email.setValue(customer.email);
      }
      if (!!customer.phone1) {
        const text = (customer.phone1).replace(/[_\W]+/g, '');
        const mask = (text.length < 11) ? '(99) 9999-9999?9' : '(99) 9 9999-9999';

        customerGroup.controls.phone1.setValue(text);
        VMasker($('[formControlName="phone1"]')[0]).maskPattern(mask);
        customerGroup.controls.phone1.setValue(customer.phone1);
      }
    }
  }

  changeType(e) {

    if (e.value == "is_unavailable") {
      this.f.is_unavailable.setValue(true);
      this.f.is_solicitation.setValue(false);
      this.f.is_warning.setValue(false);
    } else if (e.value == "is_warning") {
      this.f.is_unavailable.setValue(false);
      this.f.is_solicitation.setValue(false);
      this.f.is_warning.setValue(true);
    } else if (e.value == "is_solicitation") {
      this.f.is_warning.setValue(false);
      this.f.is_unavailable.setValue(false);
      this.f.is_solicitation.setValue(true);
    } else {
      this.f.is_unavailable.setValue(false);
      this.f.is_solicitation.setValue(false);
      this.f.is_warning.setValue(false);
    }
    this.f.is_unavailable.updateValueAndValidity();
    this.f.is_warning.updateValueAndValidity();
    this.f.is_solicitation.updateValueAndValidity();
  }

  async onSubmit(copyRegister?: boolean) {
    let $this = this;
    let dataSend = { ...this.entityForm.getRawValue() };

    const dateDisplay = dataSend.start_date;

    dataSend.start_date = moment(
      dataSend.start_date,
      environment.date_format_moment
    ).format(environment.date_format_server);

    dataSend.end_date = moment(
      dataSend.end_date,
      environment.date_format_moment
    ).format(environment.date_format_server);

    dataSend.event_date = moment(
      dataSend.event_date,
      environment.date_format_moment
    ).format(environment.date_format_server);

    if (dataSend.customer && dataSend.customer.phone1) {

      const textPhone1 = (dataSend.customer.phone1).replace(/[_\W]+/g, '');
      if (textPhone1 && textPhone1.length < 11) {
        Swal.fire({
          title: "Erro",
          html: `
      'O número de telefone é inválido!<br>
      Verifique se o mesmo foi digitado corretamente, considerando também o nono dígito'
      `,
          icon: "warning"
        });
        return;

      }
    }




    if (!$this.entityId) {
      dataSend.start = `${dataSend.start_date} ${dataSend.start_time}:00`;
      dataSend.end = `${dataSend.end_date} ${dataSend.end_time}:00`;
    } else {
      dataSend.start = `${dataSend.start_date} ${dataSend.start_time}`;
      dataSend.end = `${dataSend.end_date} ${dataSend.end_time}`;
    }

    if (
      dataSend.start &&
      moment(dataSend.start, 'YYYY-MM-DD HH:mm:ss').isBefore(moment().startOf('d'))
    ) {

      let resultDayAppointment = await Swal.fire({
        title: "Atenção!",
        html: `
      O dia do agendamento informado é um dia passado!<br>
      Tem certeza que deseja seguir com o agendamento?
      `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      });

      if (!resultDayAppointment.value) {
        return;
      }

    }

    if (
      (
        !!!dataSend.is_unavailable &&
        !!!dataSend.is_solicitation &&
        !!!dataSend.is_warning
      ) &&
      dataSend.id_reasons != environment.reason_start &&
      dataSend.id_reasons != environment.reason_return &&
      dataSend.id_reasons != environment.reason_take_measures
    ) {
      if (
        (!!!dataSend.customer.code ||
          dataSend.customer.code == 'null')
      ) {
        Swal.fire({
          title: "Erro",
          html: `
      O código do cliente não foi informado! Caso o motivo do agendamento seja diferente
      de atendimento inicial, retorno ou tirar medidas você precisa informar um setor
      específico para habilitar a inserção.
      `,
          icon: "warning",
        });
        return;
      }
    }

    if (dataSend.customer.code && dataSend.customer.code != 'null') {
      dataSend.customer.code = String(dataSend.customer.code).replace('V', '').replace('L', '').replace('VL', '');
      switch (String(dataSend.id_section)) {
        case '1':
          dataSend.customer.code += 'V';
          break;
        case '2':
          dataSend.customer.code += 'L';
          break;
        case '3':
          dataSend.customer.code += 'VL';
          break;
      }
    }



    // Aplicar validação para mostrar so em atendimento inicial
    if (dataSend.id_reasons == environment.reason_start) {
      const dataMessage = `
      Confirme com a cliente os dados do agendamento
      <strong>Data</strong>:${dateDisplay} ${dataSend.start_time}.<br>
      E não esqueça de confirmar o endereço do atelie para a cliente;
      `;

      const result = await Swal.fire({
        title: "Atenção",
        html: dataMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Já confirmei",
        cancelButtonText: "Fechar"
      });

      if (!result.value) {
        return;
      }
    }

    if (!!!dataSend.customer.hasEmail) {
      delete dataSend.customer.hasEmail;
      delete dataSend.customer.hasEmail;
    }

    $this.sendToServer(dataSend, copyRegister);
    $this.inLoading = true;
  }

  async sendToServer(dataSend, copyRegister) {
    let $this = this;

    if   (
      !!!dataSend.is_unavailable &&
      !!!dataSend.is_solicitation &&
      !!!dataSend.is_warning
    ) {
      const dataMessage = `
      Deseja enviar a notificação por email/<strong>SMS</strong> ao cliente?<br><strong style='pull-right'>Notificação destinada à</strong>:
    `;

      const result = await Swal.fire({
        title: "Atenção",
        html: dataMessage,
        input: 'text',
        inputValue: dataSend.customer.name,
        inputPlaceholder: 'Notificação destinada à:',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      });


      dataSend.send_notification = (result && result.dismiss === "cancel") ? false : true;
      dataSend.send_notification_name = (result && result.value) ? result.value : '';
    }

    if (!!$this.entityId && !!!copyRegister) {
      $this.entitySvc
        .update($this.entityId, dataSend)
        .subscribe(
          $this.successUpdateCallback,
          $this.errorUpdateCallback,
          () => {
            $this.inLoading = false;
          }
        );
    } else {
      $this.entitySvc
        .create(dataSend)
        .subscribe(
          $this.successCreateCallback,
          $this.errorCreateCallback,
          () => {
            $this.inLoading = false;
          }
        );
    }
  }

  copySave() {
    this.onSubmit(true);
  }

  goToCalendar(item) {
    let dateString = moment(item.start).format("YYYY-MM-DD");
    window.localStorage.setItem("currentDay", moment(dateString));
    window.localStorage.setItem("currentView", "agendaDay");
    this.router.navigate(["/admin/schedule/calendar"]);
  }

  async confirmDelete() {
    let $this = this;

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
    });

    let result = await Swal.fire({
      title: "Atenção!",
      text: "Deseja realmente remover o agendamento?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, desejo!",
      cancelButtonText: "Não"
    });

    if (
      result.value
    ) {
      const dataSend: any = {};
      if (!!!$this.entityObject.is_solicitation &&
        !!!$this.entityObject.is_unavailable) {
        const dataMessage = `
              Deseja enviar a notificação do cancelamento por email/<strong>SMS</strong> ao cliente ?
            `;

        const resultNotification = await Swal.fire({
          title: "Atenção",
          html: dataMessage,
          input: 'text',
          inputValue: this.entityObject.customer.name,
          inputPlaceholder: 'Notificação destinada à:',
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não"
        });

        dataSend.send_notification = (resultNotification && resultNotification.dismiss === "cancel") ? false : true;
        dataSend.send_notification_name = (resultNotification && resultNotification.value) ? resultNotification.value : '';
      }

      // dataSend.send_notification = !!resultNotification.value;
      dataSend.id = $this.entityId;

      $this.entitySvc.deleteNotification(dataSend).subscribe(
        () => {
          $this.entityForm.reset();
          $this.closeDialog.emit({
            event: "close"
          });
          Toast.fire({
            icon: "success",
            title: "Agendamento removido com sucesso!"
          });
        },
        error => {
          Swal.fire({
            title: "Erro",
            html: `
            Não foi possível remover o agendamento!<br>
            Tente novamente, e em caso de persistência entre em contato com o suporte
            `,
            icon: "warning"
          });
        }
      );
    }
  }

  changeHour(e) {

    if (e.target.value == "19:00") {
      this.entityForm.controls.end_time.setValue("19:00");
      return;
    }
    var hour = e.target.value;
    var dateNow = moment().format(environment.date_format_moment);
    var timeMinutes = moment(
      `${dateNow} ${hour}`,
      `${environment.date_format_moment} HH:mm`
    ).format("mm");
    var momentDate = moment(
      `${dateNow} ${hour}`,
      `${environment.date_format_moment} HH:mm`
    );
    var finalDate;
    console.log(timeMinutes);
    // this.entityForm.controls.start_time.setValue(dateNow.format("HH:mm"));
    if (timeMinutes == "30") {
      finalDate = momentDate.add(30, "m").format("HH:mm");
    } else {
      finalDate = momentDate.add(1, "h").format("HH:mm");
    }
    this.entityForm.controls.end_time.setValue(finalDate);
  }

  closeDialogCall(e) {
    this.closeDialog.emit({
      event: e
    });
  }

  async confirmScheduele() {
    const dataSend: any = this.entityObject;

    dataSend.is_solicitation = false;
    this.sendToServer(dataSend, false)
  }
}
