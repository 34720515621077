import { IResource } from '../interfaces/IResouce';
import { Resource } from './resource.model';

export class User extends Resource implements IResource {
    name: string;
    username: string;
    color_identifier: string;
    role_display_name: string;
    email: string;
    status: number;
    role: string

    constructor(objectJson?: any) {
        super(objectJson);
    }

}
