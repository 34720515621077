import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { UserService } from "src/app/core/services/user.service";
import { User } from "src/app/core/models/user.model";

@Component({
  selector: "app-legend-providers",
  templateUrl: "./legend-providers.component.html",
  styleUrls: ["./legend-providers.component.scss"]
})
export class LegendProvidersComponent implements OnInit {
  usersList: User[];
  selecteUser = [];
  allInclude = false;
  listUsersSelected: number[] = [];
  constructor(
    public dialogRef: MatDialogRef<LegendProvidersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userSvc: UserService
  ) {
    if (data && data.listUsersSelected) {
      this.listUsersSelected = [...data.listUsersSelected];
      this.setInitialValue();
    }
  }

  getUsersProvider() {
    const filter = {
      status: 1,
      qtd: -1,
      roles:['consultant']
    };
    this.userSvc.list(filter).subscribe(response => {
      this.usersList = response.data;
      this.setInitialValue();
    });
  }

  ngOnInit() {
    this.getUsersProvider();
  }

  setInitialValue() {
    // this.listUsersSelected = this.usersList.map((element)=> element.id );
    if (this.usersList) {
      let allInclude = true;
      this.usersList.forEach((element, index) => {
        if (this.listUsersSelected.includes(element.id)) {
          this.selecteUser[index] = true;
        } else {
          allInclude = false;
        }
      });
      this.allInclude = allInclude;
    }
  }

  setFilter(filter) {
    if (this.allInclude && this.listUsersSelected.length == this.usersList.length) {
      this.dialogRef.close([]);
    } else {
      this.dialogRef.close(filter);
    }
  }

  onNoClick(): void {
    this.dialogRef.close('close');
  }

  setSelectedUser(e) {
    if (!!e.checked) {
      this.listUsersSelected.push(e.source.value);
    } else {
      var indexElement = this.listUsersSelected.findIndex(
        element => element == e.source.value
      );
      if (indexElement >= 0) {
        this.listUsersSelected.splice(indexElement, 1);
      }
    }
  }

  setSelectedAllUser(e) {
    if (!!e.checked) {
      this.listUsersSelected = this.usersList.map(element => element.id);
      this.usersList.forEach((element, index) => {
        this.selecteUser[index] = true;
      });
    } else {
      this.listUsersSelected = [];
      this.selecteUser = [];
    }
  }
}
