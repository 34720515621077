import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-sidebar',
  templateUrl: './control-sidebar.component.html',
  styleUrls: ['./control-sidebar.component.sass']
})
export class ControlSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
