import { Resource } from './resource.model';
import { IResource } from '../interfaces/IResouce';

export class Seamstress extends Resource implements IResource {
  name: string;

  constructor(objectJson?: any) {
      super(objectJson);
  }

}
