import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { User } from 'src/app/core/models/user.model';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { Appointment } from 'src/app/core/models/appointment.model';
import { environment } from './../../../environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  environment = environment
  user: User;
  warningSchedulesList: Appointment[] = []
  constructor(
    public authSvc: AuthService,
    public appointmentSvc: AppointmentService,
    public changeDetector: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.user = this.authSvc.getUser()
    this.setWarningList()
    this.changeDetector.detectChanges()
  }

  logout(){
    this.authSvc.logout()
  }

  async setWarningList(){
    let  returnWarningSchedulesList = await this.getWarningAppointments();
    this.warningSchedulesList = returnWarningSchedulesList.data;
    this.changeDetector.detectChanges()
    setTimeout((() => {
      requestAnimationFrame((this.setWarningList).bind(this))
    }).bind(this), 20000);
  }

  async getWarningAppointments(){
      return await this.appointmentSvc.getWarningSchedules().toPromise();
  }

}
