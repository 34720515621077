import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { Observable } from "rxjs";

import { User } from "../../core/models/user.model";
import { tap } from "rxjs/operators";

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private router: Router) { }

  check(): boolean {
    return localStorage.getItem("user") ? true : false;
  }

  login(credentials): Observable<boolean> {
    return this.http
      .post<any>(`${environment.apis.info_register_api}/v1/auth`, credentials)
      .pipe(
        tap(data => {
          localStorage.setItem("access_token", data.access_token);
          localStorage.setItem("refresh_token", data.refresh_token);
          localStorage.setItem("token_type", data.token_type);
          localStorage.setItem("user", btoa(JSON.stringify(data.user)));
        })
      );
  }

  logout(): void {
    this.http
      .get(`${environment.apis.info_register_api}/v1/auth/logout`)
      .subscribe(
        resp => {
          localStorage.clear();
          this.router.navigate(["auth/login"], { replaceUrl: true });
        },
        error => {
          localStorage.clear();
          this.router.navigate(["auth/login"], { replaceUrl: true });
        }
      );
  }

  refreshAccessToken(): Observable<any> {
    const refresh_token = this.getRefreshToken();
    return this.http
      .post(`${environment.apis.info_register_api}/v1/auth/refresh_token`, { refresh_token })
      .pipe(
        tap((data: any) => {
          localStorage.setItem("access_token", data.access_token);
          localStorage.setItem("refresh_token", data.refresh_token);
          localStorage.setItem("token_type", data.token_type);
        })
      );
  }

  getAccessToken() {
    try {
      return localStorage.getItem("access_token") || null;
    } catch (error) {
      return null;
    }
  }

  getRefreshToken() {
    try {
      return localStorage.getItem("refresh_token") || null;
    } catch (error) {
      return null;
    }
  }

  getUser(): User {
    return localStorage.getItem("user")
      ? JSON.parse(atob(localStorage.getItem("user")))
      : null;
  }

  setUser(): Observable<any> {
    return this.http
      .get<any>(`${environment.apis.info_register_api}/v1/auth`)
      .pipe(
        tap(data => {
          if (data.user) {
            localStorage.setItem("user", btoa(JSON.stringify(data.user)));
            return true;
          }
          return false;
        })
      );
  }

  resetPassword(data: any) {
    return this.http.post<any>(
      `${environment.apis.info_register_api}/password/email`,
      data
    );
  }

  setNewPassword(data: any) {
    return this.http.post<any>(
      `${environment.apis.info_register_api}/password/reset`,
      data
    );
  }
}
