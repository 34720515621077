import { IResource } from '../interfaces/IResouce';
import { extend } from 'webdriver-js-extender';
import { Resource } from './resource.model';

export class EventReason extends Resource implements IResource {
    limit_day: number;
    name: string;


    constructor(objectJson?: any) {
        super(objectJson);
    }

}
