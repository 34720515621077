import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/user.model';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.sass']
})
export class ScheduleComponent implements OnInit {
  usersList: User[]
  constructor(
    public userSvc: UserService
  ) { }

  ngOnInit() {
    this.getUsersProvider();
  }

  getUsersProvider() {
    const filter = {
      status: 1
    };
    this.userSvc.list(filter).subscribe(response => {
      this.usersList = response.data;
    });
  }

}
