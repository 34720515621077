import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ControlSidebarComponent } from './control-sidebar/control-sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { BaseListComponent } from './base-list/base-list.component';
import { BaseManageComponent } from './base-manage/base-manage.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { SecuredDirective } from './directives/secured.directive';
import { PaginatorComponent } from './paginator/paginator.component';
import { DateRangeComponent } from './date-range/date-range.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ScheduleRegisterComponent } from './dialogs/schedule-register/schedule-register.component';
import { SchedueleManageComponent } from './scheduele-manage/scheduele-manage.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import {MatInputModule} from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
import { LegendProvidersComponent } from './legend-providers/legend-providers.component';
import {MatTabsModule} from '@angular/material/tabs';
import { NgxMaskModule } from 'ngx-mask';
import {MatRadioModule} from '@angular/material/radio';
import { HeaderTopNavigationComponent } from './header-top-navigation/header-top-navigation.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { TelefoneMaskDirective } from './directives/telefone-mask.directive';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    SidebarComponent,
    ControlSidebarComponent,
    FooterComponent,
    HeaderComponent,
    LoadingComponent,
    SecuredDirective,
    PaginatorComponent,
    DateRangeComponent,
    SchedueleManageComponent,
    ScheduleRegisterComponent,
    LegendProvidersComponent,
    HeaderTopNavigationComponent,
    OnlyNumberDirective,
    FileUploadComponent,
    TelefoneMaskDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    NgxMaskModule.forChild(),
    MatRadioModule,
    MatSlideToggleModule
  ],
  exports: [
    SidebarComponent,
    ControlSidebarComponent,
    FooterComponent,
    HeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    LoadingComponent,
    SecuredDirective,
    PaginatorComponent,
    DateRangeComponent,
    MatDialogModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SchedueleManageComponent,
    ScheduleRegisterComponent,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatRadioModule,
    HeaderTopNavigationComponent,
    OnlyNumberDirective,
    MatSlideToggleModule
  ],
  entryComponents:[
    ScheduleRegisterComponent,
    LegendProvidersComponent
  ]
})
export class SharedModule { }


