import { Directive, ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[OnlyNumber]'
})

export class OnlyNumberDirective {
    // Allow decimal numbers. The \. is only allowed once to occur
    private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);

    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home' ];
    @Output() afterChange = new EventEmitter();
    constructor(private el: ElementRef) {
    }

    @HostListener('keypress', [ '$event' ])
    onkeypress(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        let current: string = this.el.nativeElement.value;
        // We need this because the current value on the DOM element
        // is not yet updated with the value from this event
        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    blockPaste(event: KeyboardEvent) {
        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event['clipboardData'].getData('Text'));

        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    @HostListener('change', [ '$event' ])
    onChangeValue(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let newValue = "";
        let current: string = this.el.nativeElement.value;
        // We need this because the current value on the DOM element
        // is not yet updated with the value from this event
        if(typeof current === 'string'){
            current.split('').forEach(element => {
                let next: string = newValue.concat(element);
                if (String(next).match(this.regex)) {
                    newValue = next
                }
            });
        }
        this.el.nativeElement.value = newValue
        event.preventDefault()
        this.afterChange.emit({
            value: newValue
        })
    }
}
