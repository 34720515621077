import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Serializer } from '../models/serializer.model';
import { TemplateSms } from '../models/template-sms.model';
import { ResourceService } from './resource.service';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TemplatesSmsService extends ResourceService<TemplateSms> {

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.info_register_api,
      'v1',
      'templates-sms',
      new Serializer<TemplateSms>(new TemplateSms)
    )
  }

  getVariables(){
   return of([
      {key:"__CUSTOMER_NAME__", description: "Nome do cliente"},
      {key:"__APPOINTMENT_REASON_NAME__", description: "Motivo do agendamento"},
      {key:"__APPOINTMENT_START_DATE__", description: "Data do agendamento"},
      {key:"__APPOINTMENT_START_TIME__", description: "Horário do agendamento"},
    ])
  }



}
