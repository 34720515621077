import { IResource } from "../interfaces/IResouce";
import { Resource } from "./resource.model";
import { Customer } from "./customer.model";

export class Appointment extends Resource implements IResource {
  start: string;
  end: string;
  notes: string;
  hash: string;
  color: string;
  warning_schedule: boolean;
  is_solicitation: boolean;
  is_fake: boolean;
  is_unavailable: boolean;
  is_warning: boolean;
  id_users_provider: number;
  id_users_customer: number;
  id_event_reasons: number;
  id_reasons: number;
  customer_name: string;
  provider_name: string;
  seamstress_name: string;
  reason_name: string;
  audits: any;
  event_reason_name: string;
  customer: Customer;

  constructor(objectJson?: any) {
    super(objectJson);
  }

  fromJson(objectJson?: Resource) {
    for (const key in objectJson) {
      if (objectJson.hasOwnProperty(key)) {
        if (key == "customer") {
          this[key] = new Customer(objectJson[key]);
        } else {
          this[key] = objectJson[key];
        }
      }
    }
  }

  toString() {
    if (this.customer) {
      return `${this.customer.name} | CÓD: ${this.customer.code || ""} | ${
        this.customer.phone1
      }  | M: ${this.reason_name} | E: ${this.event_reason_name} | ${
        this.provider_name
      } | ${this.notes || ""}`;
    }
    // return `${this.customer_name}  | ${this.customer.code} | ${this.customer.name} | ${this.customer.phone} | ${this.customer.email} | ${this.reason_name} | ${this.event_reason_name} | ${this.provider_name} | ${this.notes}`;
    return `${this.customer_name} | ${this.provider_name} | ${this.reason_name}`;
  }

  getType() {
    if (this.is_unavailable) {
      return "is_unavailable";
    }
    if (this.is_solicitation) {
      return "is_solicitation";
    }

    return "is_appointment";
  }

  setType(type) {
    if (type == "is_unavailable") {
      this.is_unavailable = true;
    } else if (type == 'is_solicitation') {
      this.is_solicitation = true;
    } else {
      this.is_unavailable = false;
      this.is_solicitation = false;
    }
  }
}
