import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { Role } from '../models/role.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Serializer } from '../models/serializer.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends ResourceService<Role> {

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.info_register_api,
      'v1',
      'roles',
      new Serializer<Role>(new Role)
    )
  }


  public list(): Observable<any> {
    return of({
      data: [
        { id: "admin", display_name: 'Administrador(a)' },
        { id: "consultant", display_name: 'Consultor(a)' },
        { id: "secretary", display_name: 'Secretário(a)' },
        { id: "seamstress", display_name: 'Costureiro(a)' }
      ]
    });     
  }



}
