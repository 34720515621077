import { Injectable } from "@angular/core";
import { ResourceService } from "./resource.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Serializer } from "../models/serializer.model";
import { of, Observable } from "rxjs";
import { Customer } from "../models/customer.model";
declare var moment: any;
@Injectable({
  providedIn: "root"
})
export class CustomerService extends ResourceService<Customer> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.info_register_api,
      "v1",
      "customers",
      new Serializer<Customer>(new Customer)
    );
  }

  getByCode(code: string): Observable<any> {
    return this.httpClient.post(
      `${this.url}/${this.version}/${this.endpoint}/getByCode`,
      {
        code
      }
    );
  }
}
