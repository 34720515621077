import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { Appointment } from '../models/appointment.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Serializer } from '../models/serializer.model';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
declare var moment: any;
@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends ResourceService<Appointment> {

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.apis.info_register_api,
      'v1',
      'appointments',
      new Serializer<Appointment>(new Appointment)
    )
  }

  public listStatus(): Observable<any> {
    return of({
      data: [
        { id: 1, display_name: 'Ativo' },
        { id: 2, display_name: 'Inativo' },
        { id: 3, display_name: 'Bloqueado' }
      ]
    });
  }

  getWarningSchedules() {
    let start = moment().format('YYYY-MM-DD')
    let filter = {
      warning_schedule: true,
      qtd: -1,
      start
    }
    return this.list(filter);
  }

  create(item: Appointment | FormData, options?: any): Observable<Appointment> {
    if (options) {
      return this.httpClient
        .post<Appointment>(`${this.url}/${this.version}/${this.endpoint}/dynamic`, item, options)
        .pipe(map(data => new Object(data) as Appointment));
    }
    return this.httpClient
      .post<Appointment>(`${this.url}/${this.version}/${this.endpoint}/dynamic`, item)
      .pipe(map(data => new Object(data) as Appointment));
  }

  sendSmsToAppointment(id: number, item: { message: string, send_notification_name:string }): Observable<Appointment> {
    return this.httpClient
      .post<Appointment>(`${this.url}/${this.version}/${this.endpoint}/${id}/sendSmsToAppointment`, item)
      .pipe(map(data => this.serializer.fromJson(data) as Appointment));
  }

  update(id: number, item: Appointment): Observable<Appointment> {
    return this.httpClient
      .put<Appointment>(`${this.url}/${this.version}/${this.endpoint}/dynamic/${id}`, item)
      .pipe(map(data => this.serializer.fromJson(data) as Appointment));
  }

  deleteNotification(dataSend: { id: number, send_notification: boolean }) {
    return this.httpClient
      .post(`${this.url}/${this.version}/${this.endpoint}/delete/${dataSend.id}`, dataSend);
  }

  getSolicitationsDisplay(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/${this.version}/${this.endpoint}/solicitationsNumber`)
  }




}
